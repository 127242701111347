.sign-modal {
  .modal-sign-log__form {
    height: 570px;
    @media (max-width: $mobile-big) {
      width: 95vw;
      height: 85%;
    }
    @media (max-width: $mobile) {
      height: 100vh;
      width: 100vw;
    }
  }
  .modal-sign-log__code-block {
    display: none;
  }
}
