.control-devices {
  background: $light-grey;
  font-size: 0;
  @media (max-width: $tablet) {
    padding-bottom: 10px;
  }
  @media (max-width: $mobile-big) {
    padding-bottom: 6px;
  }
  @media (max-width: $mobile) {
   padding-bottom: 4px;
  }
  .section-big-block {
    .section-title {
      width: 70%;
      text-align: center;
      @media (max-width: $desktop) {
        width: 80%;
      }
      @media (max-width: $tablet) {
        width: 90%;
      }
      @media (max-width: $mobile-big) {
        width: 100%;
        text-align: start;
      }
    }
    &__descr {
      margin-top: 26px;
      width: 70%;
      @media (max-width: $tablet) {
        width: 90%;
      }
      @media (max-width: $mobile) {
       margin-top: 17px;
       width: 100%;
      }
    }
    &__list {
      row-gap: 10px;
      @media (max-width: $tablet) {
        row-gap: 2px;
      }
      @media (max-width: $mobile-big) {
        margin-top: 19px;
        width: 100%;
        row-gap: 18px;
      }
      @media (max-width: $mobile) {
       margin-top: 22px;
       row-gap: 20px;
      }
    }
    &__img {
      width: 69%;
      margin-top: 60px;
      @media (min-width: $desktop) {
        opacity: 0;
        transform: scale(0.5);
      }
      @media (max-width: $desktop) {
        margin-top: 32px;
      }
      @media (max-width: $tablet) {
        width: 95%;
        margin-top: 38px;
      }
      @media (max-width: $mobile-big) {
        margin-left: 9px;
        width: 96%;
      }
      @media (max-width: $mobile) {
       margin-top: 45px;
       margin-left: 7px;
      }
    }
  }
}
