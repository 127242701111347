.modal-sign-log {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  @media (max-width: $mobile-big) {
    padding-top: 12%;
    align-items: flex-start;
  }
  @media (max-width: $mobile) {
    padding: 0;
    align-items: center;
  }
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
  &__form {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 65%;
    max-width: 500px;
    padding: 20px;
    background: $white;
    border-radius: 24px;

    @media (max-width: $mobile) {
      width: 100vw;
      border-radius: 0;
      padding-bottom: 84px;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  &__title {
    font-family: $additional-font;
    font-size: 28px;
    font-weight: 700;
    line-height: 148%;
    @media (max-width: $mobile-big) {
      letter-spacing: 1.4px;
    }
    @media (max-width: $mobile) {
      letter-spacing: normal;
      font-size: 20px;
    }
  }
  &__logo {
    width: 105px;
  }

  &__body {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 32px;
    @media (max-width: $mobile-big) {
      margin-top: 18px;
    }
    @media (max-width: $mobile) {
      margin-top: 19px;
    }
  }
  &__code-block {
    margin-top: 20px;
  }
  &__bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__btns {
    display: flex;
    align-items: center;
    column-gap: 16px;
    @media (max-width: $mobile-big) {
      column-gap: 16px;
      justify-content: space-between;
    }
    @media (max-width: $mobile) {
      flex-direction: column-reverse;
      row-gap: 8px;
    }
  }
  &__close-btn {
    @include btnReset();
    padding: 16px 24px;
    padding: 23px;
    height: 56px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    border-radius: 12px;
    background: #f5f5f5;
    transition: all 0.2s ease-in-out;

    @media (max-width: $mobile) {
      width: 100%;
      padding: 14px;
      height: 48px;
    }
    &:hover {
      background-color: $ligh-brown;
      color: $white;
    }
  }
  &__next-step-btn {
    position: relative;
    @include btnReset();
    padding: 16px 48px;
    padding: 23px;
    height: 56px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    border-radius: 12px;
    background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
    transition: all 0.2s ease-in-out;

    @media (max-width: $mobile) {
      padding: 14px;
      height: 48px;
    }
    &:disabled {
      background: #b3bbcc;
      cursor: default;
      &:hover {
        transform: none;
      }
    }
    &:hover {
      transform: scale(1.03);
    }
    &.is-loading {
      background: linear-gradient(90.05deg, #4579f5 0.05%, #9c42f5 99.96%);
      opacity: 0.5;
      .loader {
        opacity: 1;
        visibility: visible;
      }
    }
    .loader {
      position: absolute;
      top: 23px;
      right: 23px;
      visibility: hidden;
      opacity: 0;
      transition: all 0.2s ease-in-out;
    }
  }
  &__personal {
    text-align: center;
  }
  &__personal-text {
    font-size: 14px;
    line-height: 18px;
  }
  &__personal-link {
    font-size: 14px;
    line-height: 18px;
    color: $blue;
    transition: all .2s ease-in-out;
    &:hover {
      color: $hoverColor;
    }
  }

  &__address-item {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    span {
      color: $dark-brown;
      font-size: 14px;
      line-height: 128.57%;
    }
  }

  &__address-link {
    color: $blue;
    font-size: 14px;
    line-height: 128.57%;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $hoverColor;
    }
  }
}
.code-block {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  &__title {
    color: $grey-secondary;
    font-size: 12px;
    font-weight: 600;
    line-height: 133.33%;
  }
  &__inputs {
    margin-top: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    @media (max-width: $mobile) {
      column-gap: 8px;
      flex-wrap: wrap;
    }
  }
  &__input {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 52px;
    height: 52px;
    font-size: 20px;
    line-height: 80%;
    border: 1px solid $light-grey-secondary;
    border-radius: 12px;
    transition: all 0.2s ease-in-out;
    @media (max-width: $mobile-big) {
      width: 42px;
      height: 42px;
    }
    &.error {
      border-color: $color-error;
    }
    &:focus {
      border-color: $light-blue-secondary;
      box-shadow: 0px 0px 0px 2px rgba(64, 132, 248, 0.16);
    }
    &:hover {
      background: $light-grey;
    }
  }
  &__info {
    margin-left: auto;
    padding-right: 9%;
    color: $color-error;
    font-size: 12px;
    font-weight: 600;
    line-height: 133.33%;
    @media (max-width: $mobile) {
      margin-top: 4px;
      margin-left: 0;
    }
  }

  &.is-active {
    visibility: visible;
    opacity: 1;
  }
}

.phone-input{
  padding: 0;
  font-size: 14px;
}
