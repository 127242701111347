.connect-now {
  font-size: 0;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);

  &__container {
    padding-left: 100px;
    display: flex;
    align-items: center;
    column-gap: 6%;
    @media (max-width: $desktop) {
      padding-left: 30px;
    }
    @media (max-width: $desktop) {
      width: 100%;
      position: relative;
      padding: 61px 0;
    }
    @media (max-width: $tablet) {
      flex-direction: column;
      padding: 16px 0;
      padding-bottom: 0;
    }
  }
  &__info {
    width: 64%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    @media (max-width: $desktop) {
      width: 36%;
    }
    @media (max-width: $tablet) {
      width: 100%;
    }
    @media (max-width: $mobile-big) {
      align-items: center;
      row-gap: 16px;
    }
  }
  &__img {
    max-height: 100%;
    width: 125%;
    @media (max-width: $desktop) {
      position: absolute;
      bottom: 0;
      right: -12%;
      width: 70%;
      max-width: 660px;
    }
    @media (max-width: $tablet) {
      position: static;
      width: 100%;
      margin-top: 30px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 16px;
    }
    @media (max-width: $mobile) {
      margin-top: 39px;
    }
  }
  &__promo {
    @include btnReset();
    width: fit-content;
    padding: 6px 14px;
    border-radius: 84px;
    background-color: $white;
    transition: all .2s ease-in-out;
    &:hover{
      transform: scale(1.02);
    }
    @media (max-width: $mobile-big) {
      display: none;
    }
    span {
      background-image: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
      -webkit-background-clip: text;
      color: transparent;
      -webkit-text-fill-color: transparent;
      font-size: 14px;
      font-weight: 700;
      line-height: 114.28%;
    }
  }
  &__title {
    color: $light-grey;
    font-size: 36px;
    font-weight: 700;
    line-height: 111.111%;
    @media (max-width: $tablet) {
      font-size: 32px;
      line-height: 127%;
    }
    @media (max-width: $mobile-big) {
      width: 60%;
      text-align: center;
    }
    @media (max-width: $mobile) {
      font-size: 20px;
      line-height: 23px;
    }
  }
  &__descr {
    color: $white;
    font-size: 16px;
    line-height: 137.5%;
    hyphens: none;
    @media (max-width: $tablet) {
      width: 55%;
    }
    @media (max-width: $mobile-big) {
      width: 75%;
      text-align: center;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}
