.planing-works {
  &__container {
    display: flex;
    justify-content: center;
    @media (max-width: $desktop) {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .section-block {
    flex-direction: row-reverse;
    padding-left: 100px;
    column-gap: 6%;
    @media (max-width: $desktop) {
      padding-left: 0px;
      width: 100%;
      position: relative;
      flex-direction: row;
      width: 100%;
    }
    @media (max-width: $tablet) {
      flex-direction: column-reverse;
    }

    &__img {
      width: 64%;
      @media (min-width: $desktop) {
        transform: translateX(100%);
        opacity: 0;
      }
      @media (max-width: $desktop) {
        position: absolute;
        top: 0;
        right: -10.5%;
        width: 59.5%;
        max-width: 600px;
      }
      @media (max-width: $tablet) {
        position: static;
        margin-top: -25px;
        width: 100%;
        max-width: 100%;
      }
      @media (max-width: $mobile-big) {
        margin-top: 7px;
      }
      @media (max-width: $mobile) {
        margin-top: 16px;
      }
    }
    &__info {
      padding-top: 22px;
      width: 50%;
      @media (max-width: $desktop) {
        width: 45%;
        padding-top: 52px;
      }
      @media (max-width: $tablet) {
        width: 68%;
        padding-top: 0;
      }
      @media (max-width: $mobile-big) {
        width: 92%;
      }
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    &__descr {
      width: 80%;
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
    .section-list {
      width: 80%;
      @media (max-width: $tablet) {
        width: 100%;
      }
    }
  }
}
