.footer {
  padding: 82px 100px;
  background: $light-grey;
  @media (max-width: $desktop) {
    padding: 40px 50px;
  }
  @media (max-width: $desktop) {
    padding: 82px 0;
  }
  @media (max-width: $mobile) {
    padding: 24px 0;
  }
  &__container {
    display: flex;
    flex-direction: column;
    row-gap: 44px;
    @media (max-width: $mobile-big) {
      row-gap: 47px;
    }
    @media (max-width: $mobile) {
      row-gap: 31px;
    }
  }
}
.footer-top {
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: $tablet) {
    padding: 0 58px 0 10px;
  }
  @media (max-width: $mobile-big) {
    padding: 0;
    flex-wrap: wrap;
    row-gap: 32px;
  }
  @media (max-width: $mobile) {
    padding-left: 10px;
    row-gap: 17px;
  }
  &__logo {
    width: 180px;
  }
  &__btns {
    display: flex;
    column-gap: 12px;
    align-items: center;
    @media (max-width: $tablet) {
      margin-top: -6px;
    }
    @media (max-width: $mobile-big) {
      width: 100%;
    }
  }
  &__btn {
    @include btnReset();
    padding: 8px 16px;
    font-size: 14px;
    line-height: 128.57%;
    border-radius: 8px;
    border: 1px solid $text-color;
    background: transparent;
    transition: all 0.2s ease-in-out;

    &:hover {
      border-color: $hoverColor;
      color: $hoverColor;
    }
  }
  .log-in-btn {
    padding: 8px 16px;
    font-size: 14px;
    font-weight: 600;
    line-height: 128.57%;
    border-radius: 8px;
  }
}
.footer-main {
  width: 89%;
  display: flex;
  justify-content: space-between;
  @media (max-width: $tablet) {
    flex-wrap: wrap;
    width: 84%;
    padding-left: 12px;
    row-gap: 48px;
  }
  @media (max-width: $mobile-big) {
    padding-left: 0;
  }
  @media (max-width: $mobile) {
    padding-left: 10px;
    row-gap: 32px;
  }
  .footer-address {
    width: 26%;
    @media (max-width) {
      width: 27%;
    }
    @media (max-width: $tablet) {
      width: 100%;
      order: 2;
    }
  }
}
.footer-bottom {
  width: fit-content;
  display: flex;
  column-gap: 12px;
  @media (max-width: $tablet) {
    padding-left: 10px;
  }
  @media (max-width: $mobile-big) {
    padding-left: 0;
  }
  @media (max-width: $mobile) {
    padding-left: 10px;
  }
}
.footer-net-link {
  font-size: 0;
  width: 26px;
  height: 26px;
  &:hover {
    svg {
      fill: $hoverColor;
    }
  }
  &:active {
    svg {
      fill: $hoverColor;
    }
  }
  svg {
    width: inherit;
    height: inherit;
    fill: $text-color;
    transition: all 0.2s ease-in-out;
  }
}

.link-digitask.link-digitask {
  &:hover {
    svg {
      fill: $hoverDigitaskColor;
    }
  }
  &:active {
    svg {
      fill: $hoverDigitaskColor;
    }
  }
}
