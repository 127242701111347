.base-objects {
  &__container {
    width: 91%;
    display: flex;
    justify-content: center;
    @media (max-width: $desktop) {
      width: 100%;
      justify-content: flex-start;
    }
  }

  .section-block {
    flex-direction: row-reverse;
    padding-left: 100px;
    column-gap: 6%;
    @media (max-width: $desktop) {
      padding-left: 0px;
      width: 100%;
      position: relative;
      flex-direction: row;
      width: 100%;
    }
    @media (max-width: $mobile-big) {
      flex-direction: column-reverse;
    }

    &__img {
      width: 52%;
      @media (min-width: $desktop) {
        transform: translateY(100%);
        opacity: 0;
      }
      @media (max-width: $desktop) {
        position: absolute;
        top: -4%;
        right: -2%;
        width: 49%;
        max-width: 480px;
      }
      @media (max-width: $tablet) {
        top: -5%;
        right: -7%;
        width: 55%;
        max-width: 344px;
      }
      @media (max-width: $mobile-big) {
        position: static;
        width: 74%;
        margin: 0 auto;
        margin-top: 26px;
      }
      @media (max-width: $mobile) {
        margin-top: 22px;
        width: 97%;
      }
    }
    &__info {
      padding-top: 57px;
      width: 50%;
      @media (max-width: $desktop) {
        width: 45%;
      }
      @media (max-width: $tablet) {
        width: 68%;
        padding-top: 0;
      }
      @media (max-width: $mobile-big) {
        width: 92%;
      }
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    &__descr {
      width: 72%;
      @media (max-width: $desktop) {
        width: 75%;
      }
      @media (max-width: $tablet) {
        width: 48%;
      }
      @media (max-width: $mobile-big) {
        width: 74%;
      }
    }
    .section-list {
      width: 95%;
      margin-top: 10px;
      @media (max-width: $desktop) {
        margin-top: 13px;
      }
      @media (max-width: $tablet) {
        margin-top: 5px;
        width: 70%;
      }
      @media (max-width: $mobile) {
        width: 100%;
      }
    }
    button{
      margin-top: 20px;
      @media (max-width: $tablet) {
        margin-top: 15px;
      }
      @media (max-width: $mobile) {
        margin-top: 12px;
        margin-right: auto;
        margin-left: auto;
      }
    }
  }
}
