.section-block {
  display: flex;
  &__img {
    width: 50%;
  }
  &__info {
    display: flex;
    flex-direction: column;
    row-gap: 26px;
    @media (max-width: $desktop) {
      row-gap: 24px;
    }
    @media (max-width: $mobile) {
      row-gap: 17px;
    }
  }
  &__descr {
    font-size: 16px;
    line-height: 137.5%;
    hyphens: none;
    @media (max-width: $tablet) {
      width: 80%;
    }
    @media (max-width: $mobile-big) {
     width: 100%;
    }
  }
  .section-list {
    margin-top: 4px;
    @media (max-width: $tablet) {
      width: 72%;
    }
    @media (max-width: $mobile-big) {
     width: 100%;
    }
  }
  .try-free-btn {
    margin-left: 4px;
    margin-top: 14px;
  }
  .qr-block {
    width: 97%;
    margin-top: auto;
    @media (max-width: $desktop) {
      width: 90%;
    }
    @media (max-width: $tablet) {
      display: none;
    }
  }
}
