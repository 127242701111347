.try-free-btn {
  @include btnReset();
  display: flex;
  align-items: center;
  column-gap: 12px;
  &:hover {
    span {
      color: $hoverColor;
    }
    svg {
      stroke: $hoverColor;
      fill: $hoverColor;
      transform: translateX(10px);
    }
  }
  span {
    color: $blue;
    font-size: 16px;
    font-weight: 600;
    line-height: 112.5%;
    transition: all 0.2s ease-in-out;
  }
  svg {
    width: 19px;
    height: 19px;
    stroke: $blue;
    fill: $blue;
    transition: all 0.2s ease-in-out;
  }
}
