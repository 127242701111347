.footer-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  &__title {
    color: $dark-brown;
    font-size: 14px;
    font-weight: 600;
    line-height: 128.57%;
  }
  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }
  &__link {
    color: $ligh-brown;
    font-size: 14px;
    font-weight: 400;
    line-height: 128.57%;
    transition: all .2s ease-in-out;
    &:hover{
      color: $hoverColor;
    }
  }
  &__item {
    color: $ligh-brown;
    font-size: 14px;
    font-weight: 400;
    line-height: 128.57%;
  }
}

.footer-address {
  &__item {
    display: flex;
    column-gap: 8px;
    span {
      color: $ligh-brown;
      font-size: 14px;
      font-weight: 400;
      line-height: 128.57%;
    }
  }
  &__link {
    color: $blue;
    font-size: 14px;
    line-height: 128.57%;
    transition: all .2s ease-in-out;
    &:hover{
      color: $hoverColor;
    }
  }
}
