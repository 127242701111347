.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  border: 2px solid cornflowerblue;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  background-color: cornflowerblue;
  margin: 0;
  padding: 0 30px;
  z-index: 1;
  transition: .25s linear;
  transition-property: color, background-color, border-color;
  @media (hover: hover) {
    &:hover {
      background-color: #fff;
      color: cornflowerblue;
    }
  }
  &:active {
    background-color: #fff;
    color: cornflowerblue;
  }
  &.disabled,
  &[disabled] {
    pointer-events: none;
    opacity: .5;
  }
  &--secondary {
    color: cornflowerblue;
    background-color: transparent;
    @media (hover: hover) {
      &:hover {
        color: #fff;
        background-color: cornflowerblue;
      }
    }
  }
}

.btn-inline {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
  svg {
    fill: cornflowerblue;
    margin-right: 12px;
  }
}