.modal-message {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  @media (max-width: $mobile-big) {
    padding-top: 12%;
    align-items: flex-start;
  }
  @media (max-width: $mobile) {
    padding: 0;
    align-items: center;
  }
  &.is-active {
    visibility: visible;
    opacity: 1;
  }
  &__content {
    overflow: auto;
    display: flex;
    flex-direction: column;
    width: 500px;
    height: 65%;
    max-height: 288px;
    max-width: 500px;
    padding: 20px;
    background: $white;
    border-radius: 24px;
    overflow: hidden;
    @media (max-width: $mobile) {
      width: 100vw;
      border-radius: 0;
      padding-bottom: 84px;
    }
  }
  &__top {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__logo {
    width: 105px;
  }

  &__body {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    text-align: center;
    @media (max-width: $mobile-big) {
      margin-top: 18px;
    }
    @media (max-width: $mobile) {
      margin-top: 19px;
    }
  }
  &__bottom {
    margin-top: 24px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
  }
  &__close-btn {
    @include btnReset();
    padding: 16px 24px;
    padding: 23px;
    width: 100%;
    height: 66px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 100%;
    color: $white;
    border-radius: 12px;
    background: linear-gradient(90deg,#4579f5 .05%,#9c42f5 99.96%);
    transition: all 0.2s ease-in-out;


    @media (max-width: $mobile) {
      padding: 14px;
      height: 56px;
    }
    &:hover {
      transform: scale(1.02);
    }
  }
  &__address-item {
    display: flex;
    justify-content: center;
    column-gap: 8px;
    span {
      color: $dark-brown;
      font-size: 14px;
      line-height: 128.57%;
    }
  }

  &__address-link {
    color: $blue;
    font-size: 14px;
    line-height: 128.57%;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $hoverColor;
    }
  }
}
