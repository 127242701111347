.descr-point {
  @include btnReset();
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 6px 14px 6px 9px;
  border-radius: 10px;
  background: $white;
  box-shadow: 0px 4px 16px 0px $shadow;
  border: 1px solid transparent;
  user-select: none;
  transition: all 0.3s ease-in-out;
  @media (max-width: $tablet) {
    padding: 4px 10px 4px 3px;
    border-radius: 6px;
  }
  @media (max-width: $mobile-big) {
    border-radius: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    justify-content: flex-start;
    text-align: start;
  }
  
  svg {
    width: 16px;
    height: 16px;
    stroke: $light-blue;
    fill: transparent;
    flex-shrink: 0;
    @media (max-width: $tablet) {
      width: 14px;
      height: 14px;
    }
    @media (max-width: $mobile-big) {
      width: 16px;
      height: 16px;
    }
  }
  span {
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: 128.57%;
    @media (max-width: $mobile-big) {
      line-height: 137.5%;
    }
  }
}
