.control-mistakes {
  &__container {
    display: flex;
    justify-content: center;
  }
  .section-block {
    align-items: center;
    padding-left: 81px;
    column-gap: 11%;
    @media (max-width: $desktop) {
      padding-left: 0px;
      column-gap: 2%;
    }
    @media (max-width: $tablet) {
      position: relative;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: row-reverse;
    }
    @media (max-width: $mobile-big) {
      align-items: center;
      flex-direction: column-reverse;
    }

    &__img {
      width: 28%;
      @media (min-width: $desktop) {
        transform: translateY(100%);
        opacity: 0;
      }
      @media (max-width: $desktop) {
        width: 32%;
        max-width: 320px;
      }
      @media (max-width: $tablet) {
        position: absolute;
        top: 27%;
        width: 35%;
        right: 7%;
        max-width: 220px;
      }

      @media (max-width: $mobile-big) {
        position: static;
        margin-top: 25px;
        width: 48%;
      }
      @media (max-width: $mobile) {
        width: 73%;
        margin-top: 18px;
      }
    }
    &__info {
      margin-top: -12px;
      width: 56%;
      gap: 0;
      @media (max-width: $desktop) {
        width: 60%;
      }
      @media (max-width: $tablet) {
        width: 73%;
        flex-shrink: 0;
      }
      @media (max-width: $mobile-big) {
        width: 100%;
      }
    }
    .section-title {
      @media (max-width: $mobile-big) {
        width: 95%;
      }
      @media (max-width: $mobile) {
        width: 80%;
      }
    }
    &__descr {
      margin-top: 15px;
      width: 80%;
      @media (max-width: $tablet) {
        margin-top: 24px;
        width: 66%;
      }
      @media (max-width: $mobile-big) {
        width: 100%;
      }
      @media (max-width: $mobile) {
        margin-top: 16px;
      }
    }
    .section-list {
      margin-top: 20px;
      @media (max-width: $tablet) {
        margin-top: 30px;
        width: 65%;
      }
      @media (max-width: $mobile) {
        width: 100%;
        margin-top: 22px;
      }
    }
    .try-free-btn {
      margin-top: 30px;
      @media (max-width: $tablet) {
        margin-top: 38px;
      }
      @media (max-width: $mobile) {
        margin-top: 29px;
      }
    }
  }
}
