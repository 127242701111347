.mobile-check-lists {
  background: $light-grey;
  font-size: 0;
  .section-big-block__img {
    @media (min-width: $desktop) {
      opacity: 0;
      transform: scale(0.5);
    }
  }
  .descr-point:first-child {
    @media (max-width: $mobile-big) {
      width: 70%;
    }
    @media (max-width: $mobile) {
      width: 80%;
    }
  }
}
