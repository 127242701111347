.entry-modal {
  .modal-sign-log__form {
    height: 400px;
    @media (max-width: $mobile-big) {
      width: 95vw;
    }
    @media (max-width: $mobile) {
      width: 100vw;
      height: 100vh;
    }
  }
}
