.terms {
  padding: 42px 0;
  &__title {
    @media (max-width: $tablet) {
      font-size: 32px;
    }
  }
  &__descr {
    margin-top: 32px;
    font-size: 18px;
  }
  &__list {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 22px;
  }
  &__point {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__point-title {
    font-size: 22px;
  }
  &__point-descr {
    font-size: 18px;
    padding-left: 12px;
  }
  &__point-link {
    color: $blue;
    font-size: 18px;
    line-height: 128.57%;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $hoverColor;
    }
  }
  &__date {
    margin-top: 32px;
  }
}
