.burger {
  @include btnReset();
  position: relative;
  width: 42px;
  height: 42px;
  &__line {
    position: absolute;
    width: 14px;
    height: 2px;
    left: 50%;
    transform: translateX(-50%);

    background: $text-color;
    &:nth-child(1) {
      top: 35%;
    }
    &:nth-child(2) {
      top: 50%;
      transform: translate(-50%);
    }
    &:nth-child(3) {
      bottom: 30%;
    }
  }
}
