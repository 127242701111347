.main {
  overflow: hidden;
  .brends {
    margin-top: $section-gap;
    @media (max-width: $desktop) {
      margin-top: 28px;
    }
    @media (max-width: $tablet) {
      margin-top: 20px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 10px;
    }
    @media (max-width: $mobile) {
      margin-top: 50px;
    }
  }
  .comfort-pluses {
    margin-top: 78px;
    @media (max-width: $desktop) {
      margin-top: 30px;
    }
    @media (max-width: $tablet) {
      margin-top: 20px;
    }
    @media (max-width: $mobile) {
      margin-top: 18px;
    }
  }
  .services {
    margin-top: 80px;
    @media (max-width: $desktop) {
      margin-top: 64px;
    }
    @media (max-width: $tablet) {
      margin-top: 42px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 32px;
    }
    @media (max-width: $mobile) {
      margin-top: 61px;
    }
  }
  .planing-works {
    margin-top: 126px;
    @media (max-width: $desktop) {
      margin-top: 92px;
    }
    @media (max-width: $tablet) {
      margin-top: 42px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 32px;
    }
    @media (max-width: $mobile) {
      margin-top: 62px;
    }
  }
  .mob-app{
    margin-top: 86px;
    @media (max-width: $desktop) {
      margin-top: 158px;
    }
    @media (max-width: $tablet) {
      margin-top: 52px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 28px;
    }
    @media (max-width: $mobile) {
      margin-top: 58px;
    }

  }
  .base-objects{
    margin-top: 82px;
    @media (max-width: $tablet) {
      margin-top: 89px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 33px;
    }
    @media (max-width: $mobile) {
      margin-top: 63px;
    }

  }
  .mobile-check-lists {
    margin-top: 76px;
    @media (max-width: $desktop) {
      margin-top: 298px;
    }
    @media (max-width: $tablet) {
      margin-top: 88px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 26px;
    }
    @media (max-width: $mobile) {
      margin-top: 56px;
    }
  }
  .schedule-staff {
    margin-top: 80px;
    @media (max-width: $desktop) {
      margin-top: 62px;
    }
    @media (max-width: $tablet) {
      margin-top: 52px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 43px;
    }
    @media (max-width: $mobile) {
     margin-top: 73px;
    }
  }
  .control-mistakes {
    margin-top: 74px;
    @media (max-width: $desktop) {
      margin-top: 180px;
    }
    @media (max-width: $tablet) {
      margin-top: 50px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 41px;
    }
    @media (max-width: $mobile) {
     margin-top: 70px;
    }
  }
  .control-devices {
    margin-top: 70px;
    @media (max-width: $desktop) {
      margin-top: 54px;
    }
    @media (max-width: $tablet) {
      margin-top: 139px;
    }
    @media (max-width: $mobile-big) {
      margin-top: 26px;
    }
    @media (max-width: $mobile) {
     margin-top: 56px;
    }
  }
}
