.comfort-pluses {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 42px;
    @media (max-width: $tablet) {
      row-gap: 20px;
    }
    @media (max-width: $mobile-big) {
      row-gap: 26px;
    }
    @media (max-width: $mobile) {
      row-gap: 18px;
    }
  }

  .section-title {
    width: 80%;
    font-size: 36px;
    line-height: 133.33%;
    text-align: center;
    @media (max-width: $desktop) {
      width: 100%;
    }
    @media (max-width: $tablet) {
      font-size: 32px;
    }
    @media (max-width: $mobile-big) {
      width: 95%;
      font-size: 27px;
      line-height: 155.55%;
    }
    @media (max-width: $mobile) {
      font-size: 18px;
      line-height: 20px;
    }
  }
  &__grid {
    width: 65%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;
    @media (max-width: $desktop) {
      width: 81%;
    }
    @media (max-width: $tablet) {
      margin-right: 20px;
      width: 97%;
    }
    @media (max-width: $mobile-big) {
      width: 94%;
      margin-right: 0;
      row-gap: 2px;
    }
    @media (max-width: $mobile) {
      grid-template-columns: 1fr 1fr;
      .pluses-item:last-child {
        grid-column: 1 / -1;
      }
    }
  }
}
