@mixin transition($element: all, $time: 0.25s, $function: linear) {
  transition: $time $function;
  transition-property: #{$element};
}

@mixin transition-cubic(
  $time: 0.25s,
  $function: cubic-bezier(1, 0, 0, 1),
  $element: all
) {
  transition: $element $time $function;
}

//example convertVH:
//min-height: calc(#{convertVH(100)} - 20px);
//height: calc(#{convertVH(100)} / 2 + 40px);
@function convertVH($val) {
  @return calc(var(--vh, 1vh) * #{$val});
}

//example heightVH:
//@include heightVH(100, 'min');
//@include heightVH(80, 'max');
//@include heightVH(100);
@mixin heightVH($val: 100, $pre: '') {
  $vh: calc(var(--vh, 1vh) * #{$val});
  @if $pre != '' {
    #{$pre}-height: $val + vh;
    #{$pre}-height: $vh;
  } @else {
    height: $val + vh;
    height: $vh;
  }
}

@mixin scrollbar($width: 2px, $rail-color: #ccc, $bar-color: #999) {
  -webkit-overflow-scrolling: touch;
  overflow: auto;
  scrollbar-color: $bar-color $rail-color;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: $width;
    height: $width;
    background-color: $rail-color;
  }
  &::-webkit-scrollbar-track {
    background-color: $rail-color;
  }
  &::-webkit-scrollbar-thumb:vertical {
    background-color: $bar-color;
  }
  &::-webkit-scrollbar-thumb:horizontal {
    background-color: $bar-color;
  }
}

@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
}
// @include placeholder {
//     color: green;
// }

@mixin btnReset() {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
}
@mixin listReset() {
  list-style: none;
  margin: 0;
  padding: 0;
}
@mixin purpleBtnWhitArrow() {
  padding: 12px 16px 12px 24px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: $white;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  border-radius: 60px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all 0.2s ease-in-out;
  svg {
    width: 24px;
    height: 24px;
    stroke: $white;
    fill: $white;
    transition: all 0.2s ease-in-out;
  }
  &:hover {
    transform: scale(1.05);
    svg {
      transform: translateX(8px);
    }
  }
}
