.lang-select {
  position: relative;
  &--open {
    .lang-select__box {
      svg {
        transform: rotate(180deg);
      }
    }
    .lang-select__options {
      opacity: 1;
      visibility: visible;
    }
  }
  &__box {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 12px;
    width: 86px;
    height: 44px;
    border-radius: 10px;
    background: #eeeeee;
    cursor: pointer;
    svg {
      fill: none;
      stroke: $text-color;
      width: 20px;
      height: 20px;
      transition: all 0.2s ease-in-out;
    }
  }
  &__selected-option {
    img {
      width: 26px;
    }
  }
  &__options {
    @include listReset();
    z-index: 5;
    top: 110%;
    right: 0;
    width: 200px;
    position: absolute;
    display: flex;
    flex-direction: column;
    background: $white;
    border-radius: 10px;
    opacity: 0;
    visibility: hidden;
  }
  &__option {
    padding: 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    &:hover {
      background: $light-grey;
    }
    img {
      width: 26px;
    }
    span {
      color: #000;
      font-size: 13px;
      line-height: 153.84%;
    }
  }
}

// mobile
.lang-select-mob {
  .lang-select__box {
    width: inherit;
    justify-content: space-between;
    padding: 0px 16px;
    height: 56px;

    svg {
    }
  }
  .lang-select__selected-option {
    img {
    }
    span {
      flex-shrink: 0;
      font-size: 16px;
      font-weight: 600;
      margin-left: 8px;
    }
  }
  .lang-select__options {
    margin-top: 24px;
    top: inherit;
    right: inherit;
    width: 100%;
    position: static;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    background: $white;
    border-radius: 0;
    opacity: 1;
    visibility: visible;
  }
  .lang-select__option {
    padding: 16px 6px 16px 32px;
    background: $grey-primary;
    border-radius: 12px;
    column-gap: 16px;
    img {
      width: 38px;
    }
    span {
    }
    svg {
      margin-left: auto;
      width: 24px;
      height: 24px;
      fill: transparent;
      stroke: $text-color;
    }
  }
}

.lang-mob-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  // opacity: 0;
  // visibility: hidden;
  display: none;
  &.is-open {
    display: flex;
    align-items: center;
    justify-content: center;
    // opacity: 1;
    // visibility: visible;
  }
  &__content {
    overflow: auto;
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50%);
    // width: 80%;
    // height: 70%;
    width: 500px;
    height: 418px;
    padding: 20px;
    background: $white;
    border-radius: 24px;
    @media (max-width: $mobile) {
      width: 100vw;
      height: 100vh;
      border-radius: 0;
    }
  }
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 32px;
    @media (max-width: $mobile) {
      font-size: 20px;
    }
  }
  &__logo {
    width: 122px;
  }
}
