.qr-block {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: $light-grey;
  border-radius: 20px;

  &__qr-nfc {
    width: fit-content;
    padding: 4px 16px;
    color: $white;
    font-size: 12px;
    font-weight: 700;
    line-height: 150%;
    border-radius: 60px;
    background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  }
  &__descr {
    width: 55%;
    font-size: 16px;
    line-height: 137.5%;
  }
  &__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 250px;
    border-radius: 12px;
    @media (max-width: $desktop) {
      width: 200px;
    }
    @media (max-width: $desktop) {
      width: 250px;
    }
    @media (max-width: $mobile-big) {
     position: static;
    }
  }
}
