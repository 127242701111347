.burger-menu-container {
  overflow: auto;
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  display: none;
  @media (max-width: $tablet) {
    display: block;
  }
  &.is-open {
    opacity: 1;
    visibility: visible;
    .burger-menu{
      opacity: 1;
      visibility: visible;
      transform: none;
    }
  }
}
.burger-menu {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  width: 74vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 84px;
  background: $light-grey;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
  @media (max-width: $mobile-big) {
    width: 81vw;
  }
  @media (max-width: $mobile) {
    width: 72vw;
    padding: 24px 16px;
    padding-bottom: 84px;
  }
  &__logo {
    width: 122px;
  }
  &__btns {
    margin-bottom: 16px;
    position: absolute;
    top: 24px;
    right: 24px;

    @media (max-width: $mobile) {
      margin-top: 26px;
      position: static;
      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }
  }
  &__bottom {
    margin-top: auto;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    @media (max-width: $mobile) {
      row-gap: 16px;
    }
  }
  .burger-log-in-btn.burger-log-in-btn {
    display: none;
    @media (max-width: $mobile) {
      display: block;
      text-align: center;
    }
  }
  .burger-sign-in-btn.burger-sign-in-btn {
    display: block;
    text-align: center;
    padding: 12px 24px;
    border: 1px solid $text-color;
    border-radius: 12px;
    &:hover {
      border-color: $hoverColor;
    }
  }
  .burger-lang-select.burger-lang-select {
    display: block;
    width: 100%;
  }
}
.burger-menu-nav {
  @include listReset();
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  display: none;
  @media (max-width: $mobile-big) {
    margin-top: 30px;
    row-gap: 53px;
  }
  @media (max-width: $mobile) {
    margin-top: 22px;
    row-gap: 22px;
  }
  &__link {
    font-size: 16px;
    font-weight: 600;
    line-height: 18px;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $hoverColor;
    }
    &:active {
      color: $hoverColor;
    }
  }
}
.burger-menu-nets {
  display: flex;
  align-items: center;
  column-gap: 12px;
  @media (max-width: $mobile) {
    margin-top: -8px;
  }
  &__link {
    width: 26px;
    height: 26px;
  }
}
