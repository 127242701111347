.services {
  &__container {
    display: flex;
    justify-content: center;
    @media (max-width: $tablet) {
      flex-direction: column;
      row-gap: 161px;
      align-items: center;
    }
    @media (max-width: $mobile-big) {
      row-gap: 34px;
    }
    @media (max-width: $mobile) {
      row-gap: 18px;
    }
  }
  .section-block {
    width: 86%;
    column-gap: 12%;
    @media (max-width: $desktop) {
      width: 100%;
      column-gap: 2%;
    }
    @media (max-width: $tablet) {
      flex-direction: row-reverse;
    }
    @media (max-width: $mobile-big) {
      flex-direction: column-reverse;
      align-items: center;
    }
    &__descr {
      @media (max-width: $desktop) {
        margin-top: 2px;
      }
      @media (max-width: $mobile) {
        margin-top: 0;
      }
    }
    &__img {
      margin-top: 17px;
      margin-bottom: 12px;
      width: 44%;

      @media (min-width: $desktop) {
        transform: translateY(100%);
        opacity: 0;
      }
      @media (max-width: $desktop) {
        margin-top: 0;
        margin-bottom: -7px;
        max-width: 360px;
      }
      @media (max-width: $tablet) {
        margin-top: 23px;
        width: 58%;
      }
      @media (max-width: $mobile-big) {
        width: 48%;
        margin-top: 29px;
      }
      @media (max-width: $mobile) {
        margin-top: 18px;
        width: 73%;
      }
    }
    &__info {
      width: 85%;
      @media (max-width: $desktop) {
        width: 90%;
      }
      @media (max-width: $tablet) {
        width: inherit;
      }
    }
    .try-free-btn {
      @media (max-width: $mobile-big) {
        display: flex;
        justify-content: center;
      }
      @media (max-width: $mobile) {
        justify-content: flex-start;
      }
    }
  }
  .qr-info-mobile {
    display: none;
    @media (max-width: $tablet) {
      display: flex;
      width: 90%;
    }

    @media (max-width: $mobile-big) {
      width: 100vw;
      align-items: center;
      border-radius: 0px;
      padding: 29px;
      padding-bottom: 20px;
    }
    @media (max-width: $mobile) {
      padding: 24px;
      padding-bottom: 16px;
    }
    .qr-block__descr {
      @media (max-width: $tablet) {
        margin-top: 2px;
      }
      @media (max-width: $mobile-big) {
        text-align: center;
        margin-top: 2px;
        width: 64%;
      }
      @media (max-width: $mobile) {
        width: 100%;
      }
    }

    .qr-block__img {
      @media (max-width: $mobile-big) {
        width: 160px;
        margin-top: 14px;
        border-radius: 8px;
      }
      @media (max-width: $mobile) {
        margin-top: 10px;
      }
    }
  }
}
