.section-big-block {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (max-width: $desktop) {
    padding-top: 34px;
  }
  @media (max-width: $mobile-big) {
    align-items: flex-start;
  }
  &__descr {
    text-align: center;
    width: 30%;
    margin-top: 12px;
    font-size: 16px;
    line-height: 137.5%;
    @media (max-width: $desktop) {
      width: 33%;
    }
    @media (max-width: $tablet) {
      width: 50%;
    }
    @media (max-width: $mobile-big) {
      width: 63%;
      text-align: start;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  &__list {
    z-index: 1;
    position: relative;
    margin-top: 14px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 12px;
    @media (max-width: $tablet) {
      gap: 4px;
    }
    @media (max-width: $mobile-big) {
      width: 76%;
      margin-top: 24px;
      justify-content: flex-start;
      gap: 17px;
    }
    @media (max-width: $mobile) {
      margin-top: 23px;
      width: 100%;
      gap: 18px;
    }
  }
  &__img {
    z-index: 0;
    position: relative;
    width: 100%;
    @media (max-width: $desktop) {
      margin-top: -18px;
    }
    @media (max-width: $tablet) {
      margin-top: -11px;
    }
    @media (max-width: $mobile-big) {
      margin-top: -3px;
    }
    @media (max-width: $mobile) {
     margin-top: 11px;
    }
  }
}
