.go-top {
  position: fixed;
  bottom: 24px;
  right: 24px;
  opacity: 0;
  visibility: hidden;
  &.is-active{
    opacity: 1;
    visibility: visible;
  }
  &:hover {
    svg {
      fill: $hoverColor;
    }
  }
  svg {
    width: 56px;
    height: 56px;
    stroke: $white;
    transition: all .2s ease-in-out;
  }
}
