@charset "UTF-8";
/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */
@import "swiper/swiper-bundle.css";
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/open-sans/OpenSans-Regular.woff2") format("woff2"), url("../fonts/open-sans/OpenSans-Regular.woff") format("woff"), url("../fonts/open-sans/OpenSans-Regular.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-Regular.eot"), url("../fonts/open-sans/OpenSans-Regular.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../fonts/open-sans/OpenSans-SemiBold.woff2") format("woff2"), url("../fonts/open-sans/OpenSans-SemiBold.woff") format("woff"), url("../fonts/open-sans/OpenSans-SemiBold.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-SemiBold.eot"), url("../fonts/open-sans/OpenSans-SemiBold.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/open-sans/OpenSans-Bold.woff2") format("woff2"), url("../fonts/open-sans/OpenSans-Bold.woff") format("woff"), url("../fonts/open-sans/OpenSans-Bold.ttf") format("truetype"), url("../fonts/open-sans/OpenSans-Bold.eot"), url("../fonts/open-sans/OpenSans-Bold.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: url("../fonts/roboto/Roboto-Regular.woff2") format("woff2"), url("../fonts/roboto/Roboto-Regular.woff") format("woff"), url("../fonts/roboto/Roboto-Regular.ttf") format("truetype"), url("../fonts/roboto/Roboto-Regular.eot"), url("../fonts/roboto/Roboto-Regular.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: url("../fonts/roboto/Roboto-Medium.woff2") format("woff2"), url("../fonts/roboto/Roboto-Medium.woff") format("woff"), url("../fonts/roboto/Roboto-Medium.ttf") format("truetype"), url("../fonts/roboto/Roboto-Medium.eot"), url("../fonts/roboto/Roboto-Medium.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: url("../fonts/roboto/Roboto-Bold.woff2") format("woff2"), url("../fonts/roboto/Roboto-Bold.woff") format("woff"), url("../fonts/roboto/Roboto-Bold.ttf") format("truetype"), url("../fonts/roboto/Roboto-Bold.eot"), url("../fonts/roboto/Roboto-Bold.eot?#iefix") format("embedded-opentype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: url("../fonts/roboto/Roboto-Black.woff2") format("woff2"), url("../fonts/roboto/Roboto-Black.woff") format("woff"), url("../fonts/roboto/Roboto-Black.ttf") format("truetype"), url("../fonts/roboto/Roboto-Black.eot"), url("../fonts/roboto/Roboto-Black.eot?#iefix") format("embedded-opentype");
}
/* smartphones, touchscreens */
/* stylus-based screens */
/* Nintendo Wii controller, Microsoft Kinect */
/* mouse, touch pad */
/* hide scrollbar */
@-ms-viewport {
  width: device-width;
}
[data-whatintent=touch] *:focus,
[data-whatintent=mouse] *:focus {
  outline: none;
}

* {
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

*::before,
*::after {
  box-sizing: inherit;
}

html {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  scroll-behavior: smooth;
}

body {
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  color: #101d50;
  overflow-x: hidden;
  text-align: left;
  cursor: default;
  word-break: break-word;
  padding: 0;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@media (max-width: 639px) {
  body {
    font-size: 14px;
  }
}

[tabindex="-1"]:focus {
  outline: none !important;
}

[role=button] {
  cursor: pointer;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

a,
button,
[role=button],
input,
label,
select,
textarea {
  touch-action: manipulation;
  margin: 0;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input {
  overflow: visible;
  text-transform: none;
}

button,
html [type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}

button::-moz-focus-inner,
[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

button {
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  cursor: pointer;
}

input[type=date],
input[type=time],
input[type=datetime-local],
input[type=month] {
  -webkit-appearance: listbox;
}

input[type=submit] {
  width: auto;
}

input[type=number] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=search] {
  -webkit-appearance: textfield;
}

input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

textarea {
  overflow: auto;
  resize: vertical;
}

svg {
  fill: currentColor;
}
a svg, button svg {
  pointer-events: none;
}
svg:not(:root) {
  overflow: hidden;
}

img {
  max-width: 100%;
  height: auto;
  border: none;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
  margin-top: 2em;
  margin-bottom: 2em;
  border: 0;
  border-top: 1px solid hsl(0, 0%, 70%);
}

p,
ul,
ol,
dl,
table,
blockquote,
pre,
address,
figure {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  hyphens: auto;
  hyphenate-limit-chars: 6 3 3;
  hyphenate-limit-lines: 2;
  hyphenate-limit-last: always;
  hyphenate-limit-zone: 8%;
  padding: 0;
  margin: 0;
}

address {
  font-style: normal;
}

ul,
ol {
  margin: 0;
}
ul.no-style,
ol.no-style {
  padding: 0;
  list-style: none;
}

li {
  line-height: inherit;
}

blockquote {
  padding: 0;
  margin: 0;
}

code {
  padding: 0.06em 0.3em;
  color: #101d50;
  background-color: rgba(0, 0, 0, 0.08);
  border-radius: 3px;
}

kbd {
  padding: 0.06em 0.3em;
  color: #101d50;
  background-color: hsl(0, 0%, 90%);
  border-radius: 3px;
}
kbd kbd {
  padding: 0;
}

pre {
  display: block;
  width: 100%;
  margin-bottom: 0;
  overflow-x: auto;
  tab-size: 2;
  background-color: rgba(0, 0, 0, 0.08);
}
pre code {
  background: transparent;
}

[contenteditable=true]:empty:before {
  content: attr(data-placeholder);
  color: #131313;
  font-size: inherit;
  display: block; /* For Firefox */
}

select {
  appearance: none;
}
select::-ms-expand {
  display: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  color: currentColor;
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 800;
}

h2 {
  font-size: 42px;
  line-height: 51px;
  font-weight: 800;
}

h3 {
  font-size: 28px;
  line-height: 34px;
}

h4 {
  font-size: 22px;
}

.container {
  max-width: 1200px;
  width: 100%;
  margin: auto;
  padding: 0 20px;
}
@media (max-width: 1199px) {
  .container {
    padding: 0 10px;
  }
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  overflow: hidden;
}

.apple-fix {
  width: 100%;
  position: fixed;
}

.no-scroll {
  overflow: hidden;
}

.clearfix::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  min-height: 100vh;
  min-height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  overflow-x: hidden;
  position: relative;
}
.wrapper.sticky {
  overflow: unset;
}
.wrapper .header {
  flex: 0 0 auto;
}
.wrapper main {
  position: relative;
  flex: 1 0 auto;
}
.wrapper .footer {
  flex: 0 0 auto;
}

.table {
  display: table;
  width: 100%;
  border: 1px solid hsl(0, 0%, 70%);
  border-collapse: collapse;
}
.table td,
.table th {
  padding: 5px 10px;
  vertical-align: top;
  border-bottom: 1px solid hsl(0, 0%, 70%);
}
.table td:not(:first-child),
.table th:not(:first-child) {
  border-left: 1px solid hsl(0, 0%, 70%);
}
.table th {
  text-align: inherit;
}

.is-default-hidden {
  display: none !important;
}

.digitask-color.digitask-color {
  background: linear-gradient(90deg, #FF2C52 0.05%, #FD9A25 99.91%);
}

.digitask-hv-color.digitask-hv-color:hover span {
  color: #fe5840;
}
.digitask-hv-color.digitask-hv-color:hover svg {
  fill: #fe5840;
  stroke: #fe5840;
}

.digitask-hover-bg-color.digitask-hover-bg-color:hover {
  background: #fe5840;
}

.digitask-hover-color.digitask-hover-color:hover {
  color: #fe5840;
}

.digitask-hover-border-color.digitask-hover-border-color:hover {
  border-color: #fe5840 !important;
}

.digitask-go-top-hover.digitask-go-top-hover:hover svg {
  fill: #fe5840;
}

:root {
  --iti-hover-color: rgba(
      0,
      0,
      0,
      0.05
  );
  --iti-border-color: #ccc;
  --iti-dialcode-color: #999;
  --iti-dropdown-bg: white;
  --iti-spacer-horizontal: 8px;
  --iti-flag-height: 12px;
  --iti-flag-width: 16px;
  --iti-border-width: 1px;
  --iti-arrow-height: 4px;
  --iti-arrow-width: 6px;
  --iti-triangle-border: calc(var(--iti-arrow-width) / 2);
  --iti-arrow-padding: 6px;
  --iti-arrow-color: #555;
  --iti-path-flags-1x: url("/img/flags.webp");
  --iti-path-flags-2x: url("/img/flags@2x.webp");
  --iti-path-globe-1x: url("/img/globe.webp");
  --iti-path-globe-2x: url("/img/globe@2x.webp");
  --iti-flag-sprite-width: 3904px;
  --iti-flag-sprite-height: 12px;
  --iti-mobile-popup-margin: 30px;
}

.iti {
  position: relative;
  height: inherit;
}
.iti * {
  box-sizing: border-box;
}
.iti__hide {
  display: none;
}
.iti__v-hide {
  visibility: hidden;
}
.iti__a11y-text {
  width: 1px;
  height: 1px;
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  position: absolute;
}
.iti input.iti__tel-input,
.iti input.iti__tel-input[type=text],
.iti input.iti__tel-input[type=tel] {
  position: relative;
  z-index: 0;
  margin: 0 !important;
}
.iti__country-container {
  position: absolute;
  top: 0;
  bottom: 0;
  padding: var(--iti-border-width);
}
.iti__selected-country {
  padding-top: 10px;
  z-index: 1;
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  color: inherit;
  border-radius: 0;
  font-weight: inherit;
  line-height: inherit;
  text-decoration: none;
}
.iti__selected-country-primary {
  display: flex;
  align-items: center;
  height: 100%;
  border-radius: 12px 0 0 12px;
  padding: 0 var(--iti-arrow-padding) 0 var(--iti-spacer-horizontal);
}
.iti__arrow {
  margin-left: var(--iti-arrow-padding);
  width: 0;
  height: 0;
  border-left: var(--iti-triangle-border) solid transparent;
  border-right: var(--iti-triangle-border) solid transparent;
  border-top: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
[dir=rtl] .iti__arrow {
  margin-right: var(--iti-arrow-padding);
  margin-left: 0;
}
.iti__arrow--up {
  border-top: none;
  border-bottom: var(--iti-arrow-height) solid var(--iti-arrow-color);
}
.iti__dropdown-content {
  border-radius: 3px;
  background-color: var(--iti-dropdown-bg);
}
.iti--inline-dropdown .iti__dropdown-content {
  position: absolute;
  z-index: 2;
  margin-top: 3px;
  margin-left: calc(var(--iti-border-width) * -1);
  border: var(--iti-border-width) solid var(--iti-border-color);
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
}
.iti__search-input {
  width: 100%;
  border-width: 0;
  border-radius: 3px;
}
.iti__search-input + .iti__country-list {
  border-top: 1px solid var(--iti-border-color);
}
.iti__country-list {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
.iti--inline-dropdown .iti__country-list {
  max-height: 185px;
}
.iti--flexible-dropdown-width .iti__country-list {
  white-space: nowrap;
}
@media (max-width: 500px) {
  .iti--flexible-dropdown-width .iti__country-list {
    white-space: normal;
  }
}
.iti__country {
  display: flex;
  align-items: center;
  padding: 8px var(--iti-spacer-horizontal);
  outline: none;
}
.iti__dial-code {
  color: var(--iti-dialcode-color);
}
.iti__country.iti__highlight {
  background-color: var(--iti-hover-color);
}
.iti__country-list .iti__flag, .iti__country-name {
  margin-right: var(--iti-spacer-horizontal);
}
[dir=rtl] .iti__country-list .iti__flag, [dir=rtl] .iti__country-name {
  margin-right: 0;
  margin-left: var(--iti-spacer-horizontal);
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover, .iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])):hover button {
  cursor: pointer;
}
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country-primary:hover,
.iti--allow-dropdown .iti__country-container:not(:has(+ input[disabled])):not(:has(+ input[readonly])) .iti__selected-country:has(+ .iti__dropdown-content:hover) .iti__selected-country-primary {
  background-color: var(--iti-hover-color);
}
.iti .iti__selected-dial-code {
  margin-left: 4px;
}
[dir=rtl] .iti .iti__selected-dial-code {
  margin-left: 0;
  margin-right: 4px;
}
.iti--container {
  position: fixed;
  top: -1000px;
  left: -1000px;
  z-index: 1060;
  padding: var(--iti-border-width);
}
.iti--container:hover {
  cursor: pointer;
}

.iti--fullscreen-popup.iti--container {
  background-color: rgba(0, 0, 0, 0.5);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  padding: var(--iti-mobile-popup-margin);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.iti--fullscreen-popup .iti__dropdown-content {
  display: flex;
  flex-direction: column;
  max-height: 100%;
  position: relative;
}
.iti--fullscreen-popup .iti__country {
  padding: 10px 10px;
  line-height: 1.5em;
}

.iti__flag {
  --iti-flag-offset: 100px;
  height: var(--iti-flag-height);
  width: var(--iti-flag-width);
  border-radius: 1px;
  box-shadow: 0px 0px 1px 0px #888;
  background-image: var(--iti-path-flags-1x);
  background-repeat: no-repeat;
  background-position: var(--iti-flag-offset) 0;
  background-size: var(--iti-flag-sprite-width) var(--iti-flag-sprite-height);
}

.iti__ac {
  --iti-flag-offset: 0px;
}

.iti__ad {
  --iti-flag-offset: -16px;
}

.iti__ae {
  --iti-flag-offset: -32px;
}

.iti__af {
  --iti-flag-offset: -48px;
}

.iti__ag {
  --iti-flag-offset: -64px;
}

.iti__ai {
  --iti-flag-offset: -80px;
}

.iti__al {
  --iti-flag-offset: -96px;
}

.iti__am {
  --iti-flag-offset: -112px;
}

.iti__ao {
  --iti-flag-offset: -128px;
}

.iti__ar {
  --iti-flag-offset: -144px;
}

.iti__as {
  --iti-flag-offset: -160px;
}

.iti__at {
  --iti-flag-offset: -176px;
}

.iti__au {
  --iti-flag-offset: -192px;
}

.iti__aw {
  --iti-flag-offset: -208px;
}

.iti__ax {
  --iti-flag-offset: -224px;
}

.iti__az {
  --iti-flag-offset: -240px;
}

.iti__ba {
  --iti-flag-offset: -256px;
}

.iti__bb {
  --iti-flag-offset: -272px;
}

.iti__bd {
  --iti-flag-offset: -288px;
}

.iti__be {
  --iti-flag-offset: -304px;
}

.iti__bf {
  --iti-flag-offset: -320px;
}

.iti__bg {
  --iti-flag-offset: -336px;
}

.iti__bh {
  --iti-flag-offset: -352px;
}

.iti__bi {
  --iti-flag-offset: -368px;
}

.iti__bj {
  --iti-flag-offset: -384px;
}

.iti__bl {
  --iti-flag-offset: -400px;
}

.iti__bm {
  --iti-flag-offset: -416px;
}

.iti__bn {
  --iti-flag-offset: -432px;
}

.iti__bo {
  --iti-flag-offset: -448px;
}

.iti__bq {
  --iti-flag-offset: -464px;
}

.iti__br {
  --iti-flag-offset: -480px;
}

.iti__bs {
  --iti-flag-offset: -496px;
}

.iti__bt {
  --iti-flag-offset: -512px;
}

.iti__bw {
  --iti-flag-offset: -528px;
}

.iti__by {
  --iti-flag-offset: -544px;
}

.iti__bz {
  --iti-flag-offset: -560px;
}

.iti__ca {
  --iti-flag-offset: -576px;
}

.iti__cc {
  --iti-flag-offset: -592px;
}

.iti__cd {
  --iti-flag-offset: -608px;
}

.iti__cf {
  --iti-flag-offset: -624px;
}

.iti__cg {
  --iti-flag-offset: -640px;
}

.iti__ch {
  --iti-flag-offset: -656px;
}

.iti__ci {
  --iti-flag-offset: -672px;
}

.iti__ck {
  --iti-flag-offset: -688px;
}

.iti__cl {
  --iti-flag-offset: -704px;
}

.iti__cm {
  --iti-flag-offset: -720px;
}

.iti__cn {
  --iti-flag-offset: -736px;
}

.iti__co {
  --iti-flag-offset: -752px;
}

.iti__cr {
  --iti-flag-offset: -768px;
}

.iti__cu {
  --iti-flag-offset: -784px;
}

.iti__cv {
  --iti-flag-offset: -800px;
}

.iti__cw {
  --iti-flag-offset: -816px;
}

.iti__cx {
  --iti-flag-offset: -832px;
}

.iti__cy {
  --iti-flag-offset: -848px;
}

.iti__cz {
  --iti-flag-offset: -864px;
}

.iti__de {
  --iti-flag-offset: -880px;
}

.iti__dj {
  --iti-flag-offset: -896px;
}

.iti__dk {
  --iti-flag-offset: -912px;
}

.iti__dm {
  --iti-flag-offset: -928px;
}

.iti__do {
  --iti-flag-offset: -944px;
}

.iti__dz {
  --iti-flag-offset: -960px;
}

.iti__ec {
  --iti-flag-offset: -976px;
}

.iti__ee {
  --iti-flag-offset: -992px;
}

.iti__eg {
  --iti-flag-offset: -1008px;
}

.iti__eh {
  --iti-flag-offset: -1024px;
}

.iti__er {
  --iti-flag-offset: -1040px;
}

.iti__es {
  --iti-flag-offset: -1056px;
}

.iti__et {
  --iti-flag-offset: -1072px;
}

.iti__fi {
  --iti-flag-offset: -1088px;
}

.iti__fj {
  --iti-flag-offset: -1104px;
}

.iti__fk {
  --iti-flag-offset: -1120px;
}

.iti__fm {
  --iti-flag-offset: -1136px;
}

.iti__fo {
  --iti-flag-offset: -1152px;
}

.iti__fr {
  --iti-flag-offset: -1168px;
}

.iti__ga {
  --iti-flag-offset: -1184px;
}

.iti__gb {
  --iti-flag-offset: -1200px;
}

.iti__gd {
  --iti-flag-offset: -1216px;
}

.iti__ge {
  --iti-flag-offset: -1232px;
}

.iti__gf {
  --iti-flag-offset: -1248px;
}

.iti__gg {
  --iti-flag-offset: -1264px;
}

.iti__gh {
  --iti-flag-offset: -1280px;
}

.iti__gi {
  --iti-flag-offset: -1296px;
}

.iti__gl {
  --iti-flag-offset: -1312px;
}

.iti__gm {
  --iti-flag-offset: -1328px;
}

.iti__gn {
  --iti-flag-offset: -1344px;
}

.iti__gp {
  --iti-flag-offset: -1360px;
}

.iti__gq {
  --iti-flag-offset: -1376px;
}

.iti__gr {
  --iti-flag-offset: -1392px;
}

.iti__gt {
  --iti-flag-offset: -1408px;
}

.iti__gu {
  --iti-flag-offset: -1424px;
}

.iti__gw {
  --iti-flag-offset: -1440px;
}

.iti__gy {
  --iti-flag-offset: -1456px;
}

.iti__hk {
  --iti-flag-offset: -1472px;
}

.iti__hn {
  --iti-flag-offset: -1488px;
}

.iti__hr {
  --iti-flag-offset: -1504px;
}

.iti__ht {
  --iti-flag-offset: -1520px;
}

.iti__hu {
  --iti-flag-offset: -1536px;
}

.iti__id {
  --iti-flag-offset: -1552px;
}

.iti__ie {
  --iti-flag-offset: -1568px;
}

.iti__il {
  --iti-flag-offset: -1584px;
}

.iti__im {
  --iti-flag-offset: -1600px;
}

.iti__in {
  --iti-flag-offset: -1616px;
}

.iti__io {
  --iti-flag-offset: -1632px;
}

.iti__iq {
  --iti-flag-offset: -1648px;
}

.iti__ir {
  --iti-flag-offset: -1664px;
}

.iti__is {
  --iti-flag-offset: -1680px;
}

.iti__it {
  --iti-flag-offset: -1696px;
}

.iti__je {
  --iti-flag-offset: -1712px;
}

.iti__jm {
  --iti-flag-offset: -1728px;
}

.iti__jo {
  --iti-flag-offset: -1744px;
}

.iti__jp {
  --iti-flag-offset: -1760px;
}

.iti__ke {
  --iti-flag-offset: -1776px;
}

.iti__kg {
  --iti-flag-offset: -1792px;
}

.iti__kh {
  --iti-flag-offset: -1808px;
}

.iti__ki {
  --iti-flag-offset: -1824px;
}

.iti__km {
  --iti-flag-offset: -1840px;
}

.iti__kn {
  --iti-flag-offset: -1856px;
}

.iti__kp {
  --iti-flag-offset: -1872px;
}

.iti__kr {
  --iti-flag-offset: -1888px;
}

.iti__kw {
  --iti-flag-offset: -1904px;
}

.iti__ky {
  --iti-flag-offset: -1920px;
}

.iti__kz {
  --iti-flag-offset: -1936px;
}

.iti__la {
  --iti-flag-offset: -1952px;
}

.iti__lb {
  --iti-flag-offset: -1968px;
}

.iti__lc {
  --iti-flag-offset: -1984px;
}

.iti__li {
  --iti-flag-offset: -2000px;
}

.iti__lk {
  --iti-flag-offset: -2016px;
}

.iti__lr {
  --iti-flag-offset: -2032px;
}

.iti__ls {
  --iti-flag-offset: -2048px;
}

.iti__lt {
  --iti-flag-offset: -2064px;
}

.iti__lu {
  --iti-flag-offset: -2080px;
}

.iti__lv {
  --iti-flag-offset: -2096px;
}

.iti__ly {
  --iti-flag-offset: -2112px;
}

.iti__ma {
  --iti-flag-offset: -2128px;
}

.iti__mc {
  --iti-flag-offset: -2144px;
}

.iti__md {
  --iti-flag-offset: -2160px;
}

.iti__me {
  --iti-flag-offset: -2176px;
}

.iti__mf {
  --iti-flag-offset: -2192px;
}

.iti__mg {
  --iti-flag-offset: -2208px;
}

.iti__mh {
  --iti-flag-offset: -2224px;
}

.iti__mk {
  --iti-flag-offset: -2240px;
}

.iti__ml {
  --iti-flag-offset: -2256px;
}

.iti__mm {
  --iti-flag-offset: -2272px;
}

.iti__mn {
  --iti-flag-offset: -2288px;
}

.iti__mo {
  --iti-flag-offset: -2304px;
}

.iti__mp {
  --iti-flag-offset: -2320px;
}

.iti__mq {
  --iti-flag-offset: -2336px;
}

.iti__mr {
  --iti-flag-offset: -2352px;
}

.iti__ms {
  --iti-flag-offset: -2368px;
}

.iti__mt {
  --iti-flag-offset: -2384px;
}

.iti__mu {
  --iti-flag-offset: -2400px;
}

.iti__mv {
  --iti-flag-offset: -2416px;
}

.iti__mw {
  --iti-flag-offset: -2432px;
}

.iti__mx {
  --iti-flag-offset: -2448px;
}

.iti__my {
  --iti-flag-offset: -2464px;
}

.iti__mz {
  --iti-flag-offset: -2480px;
}

.iti__na {
  --iti-flag-offset: -2496px;
}

.iti__nc {
  --iti-flag-offset: -2512px;
}

.iti__ne {
  --iti-flag-offset: -2528px;
}

.iti__nf {
  --iti-flag-offset: -2544px;
}

.iti__ng {
  --iti-flag-offset: -2560px;
}

.iti__ni {
  --iti-flag-offset: -2576px;
}

.iti__nl {
  --iti-flag-offset: -2592px;
}

.iti__no {
  --iti-flag-offset: -2608px;
}

.iti__np {
  --iti-flag-offset: -2624px;
}

.iti__nr {
  --iti-flag-offset: -2640px;
}

.iti__nu {
  --iti-flag-offset: -2656px;
}

.iti__nz {
  --iti-flag-offset: -2672px;
}

.iti__om {
  --iti-flag-offset: -2688px;
}

.iti__pa {
  --iti-flag-offset: -2704px;
}

.iti__pe {
  --iti-flag-offset: -2720px;
}

.iti__pf {
  --iti-flag-offset: -2736px;
}

.iti__pg {
  --iti-flag-offset: -2752px;
}

.iti__ph {
  --iti-flag-offset: -2768px;
}

.iti__pk {
  --iti-flag-offset: -2784px;
}

.iti__pl {
  --iti-flag-offset: -2800px;
}

.iti__pm {
  --iti-flag-offset: -2816px;
}

.iti__pr {
  --iti-flag-offset: -2832px;
}

.iti__ps {
  --iti-flag-offset: -2848px;
}

.iti__pt {
  --iti-flag-offset: -2864px;
}

.iti__pw {
  --iti-flag-offset: -2880px;
}

.iti__py {
  --iti-flag-offset: -2896px;
}

.iti__qa {
  --iti-flag-offset: -2912px;
}

.iti__re {
  --iti-flag-offset: -2928px;
}

.iti__ro {
  --iti-flag-offset: -2944px;
}

.iti__rs {
  --iti-flag-offset: -2960px;
}

.iti__ru {
  --iti-flag-offset: -2976px;
}

.iti__rw {
  --iti-flag-offset: -2992px;
}

.iti__sa {
  --iti-flag-offset: -3008px;
}

.iti__sb {
  --iti-flag-offset: -3024px;
}

.iti__sc {
  --iti-flag-offset: -3040px;
}

.iti__sd {
  --iti-flag-offset: -3056px;
}

.iti__se {
  --iti-flag-offset: -3072px;
}

.iti__sg {
  --iti-flag-offset: -3088px;
}

.iti__sh {
  --iti-flag-offset: -3104px;
}

.iti__si {
  --iti-flag-offset: -3120px;
}

.iti__sj {
  --iti-flag-offset: -3136px;
}

.iti__sk {
  --iti-flag-offset: -3152px;
}

.iti__sl {
  --iti-flag-offset: -3168px;
}

.iti__sm {
  --iti-flag-offset: -3184px;
}

.iti__sn {
  --iti-flag-offset: -3200px;
}

.iti__so {
  --iti-flag-offset: -3216px;
}

.iti__sr {
  --iti-flag-offset: -3232px;
}

.iti__ss {
  --iti-flag-offset: -3248px;
}

.iti__st {
  --iti-flag-offset: -3264px;
}

.iti__sv {
  --iti-flag-offset: -3280px;
}

.iti__sx {
  --iti-flag-offset: -3296px;
}

.iti__sy {
  --iti-flag-offset: -3312px;
}

.iti__sz {
  --iti-flag-offset: -3328px;
}

.iti__tc {
  --iti-flag-offset: -3344px;
}

.iti__td {
  --iti-flag-offset: -3360px;
}

.iti__tg {
  --iti-flag-offset: -3376px;
}

.iti__th {
  --iti-flag-offset: -3392px;
}

.iti__tj {
  --iti-flag-offset: -3408px;
}

.iti__tk {
  --iti-flag-offset: -3424px;
}

.iti__tl {
  --iti-flag-offset: -3440px;
}

.iti__tm {
  --iti-flag-offset: -3456px;
}

.iti__tn {
  --iti-flag-offset: -3472px;
}

.iti__to {
  --iti-flag-offset: -3488px;
}

.iti__tr {
  --iti-flag-offset: -3504px;
}

.iti__tt {
  --iti-flag-offset: -3520px;
}

.iti__tv {
  --iti-flag-offset: -3536px;
}

.iti__tw {
  --iti-flag-offset: -3552px;
}

.iti__tz {
  --iti-flag-offset: -3568px;
}

.iti__ua {
  --iti-flag-offset: -3584px;
}

.iti__ug {
  --iti-flag-offset: -3600px;
}

.iti__us {
  --iti-flag-offset: -3616px;
}

.iti__uy {
  --iti-flag-offset: -3632px;
}

.iti__uz {
  --iti-flag-offset: -3648px;
}

.iti__va {
  --iti-flag-offset: -3664px;
}

.iti__vc {
  --iti-flag-offset: -3680px;
}

.iti__ve {
  --iti-flag-offset: -3696px;
}

.iti__vg {
  --iti-flag-offset: -3712px;
}

.iti__vi {
  --iti-flag-offset: -3728px;
}

.iti__vn {
  --iti-flag-offset: -3744px;
}

.iti__vu {
  --iti-flag-offset: -3760px;
}

.iti__wf {
  --iti-flag-offset: -3776px;
}

.iti__ws {
  --iti-flag-offset: -3792px;
}

.iti__xk {
  --iti-flag-offset: -3808px;
}

.iti__ye {
  --iti-flag-offset: -3824px;
}

.iti__yt {
  --iti-flag-offset: -3840px;
}

.iti__za {
  --iti-flag-offset: -3856px;
}

.iti__zm {
  --iti-flag-offset: -3872px;
}

.iti__zw {
  --iti-flag-offset: -3888px;
}

.iti__globe {
  background-image: var(--iti-path-globe-1x);
  background-size: contain;
  background-position: right;
  box-shadow: none;
  height: 19px;
}

@media (min-resolution: 2x) {
  .iti__flag {
    background-image: var(--iti-path-flags-2x);
  }
  .iti__globe {
    background-image: var(--iti-path-globe-2x);
  }
}
.section-big-block {
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1199px) {
  .section-big-block {
    padding-top: 34px;
  }
}
@media (max-width: 639px) {
  .section-big-block {
    align-items: flex-start;
  }
}
.section-big-block__descr {
  text-align: center;
  width: 30%;
  margin-top: 12px;
  font-size: 16px;
  line-height: 137.5%;
}
@media (max-width: 1199px) {
  .section-big-block__descr {
    width: 33%;
  }
}
@media (max-width: 959px) {
  .section-big-block__descr {
    width: 50%;
  }
}
@media (max-width: 639px) {
  .section-big-block__descr {
    width: 63%;
    text-align: start;
  }
}
@media (max-width: 479px) {
  .section-big-block__descr {
    width: 100%;
  }
}
.section-big-block__list {
  z-index: 1;
  position: relative;
  margin-top: 14px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 12px;
}
@media (max-width: 959px) {
  .section-big-block__list {
    gap: 4px;
  }
}
@media (max-width: 639px) {
  .section-big-block__list {
    width: 76%;
    margin-top: 24px;
    justify-content: flex-start;
    gap: 17px;
  }
}
@media (max-width: 479px) {
  .section-big-block__list {
    margin-top: 23px;
    width: 100%;
    gap: 18px;
  }
}
.section-big-block__img {
  z-index: 0;
  position: relative;
  width: 100%;
}
@media (max-width: 1199px) {
  .section-big-block__img {
    margin-top: -18px;
  }
}
@media (max-width: 959px) {
  .section-big-block__img {
    margin-top: -11px;
  }
}
@media (max-width: 639px) {
  .section-big-block__img {
    margin-top: -3px;
  }
}
@media (max-width: 479px) {
  .section-big-block__img {
    margin-top: 11px;
  }
}

.descr-point {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 4px;
  padding: 6px 14px 6px 9px;
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid transparent;
  user-select: none;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 959px) {
  .descr-point {
    padding: 4px 10px 4px 3px;
    border-radius: 6px;
  }
}
@media (max-width: 639px) {
  .descr-point {
    border-radius: 0;
    padding: 0;
    background: transparent;
    box-shadow: none;
    justify-content: flex-start;
    text-align: start;
  }
}
.descr-point svg {
  width: 16px;
  height: 16px;
  stroke: #3390ff;
  fill: transparent;
  flex-shrink: 0;
}
@media (max-width: 959px) {
  .descr-point svg {
    width: 14px;
    height: 14px;
  }
}
@media (max-width: 639px) {
  .descr-point svg {
    width: 16px;
    height: 16px;
  }
}
.descr-point span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 128.57%;
}
@media (max-width: 639px) {
  .descr-point span {
    line-height: 137.5%;
  }
}

.section-block {
  display: flex;
}
.section-block__img {
  width: 50%;
}
.section-block__info {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
}
@media (max-width: 1199px) {
  .section-block__info {
    row-gap: 24px;
  }
}
@media (max-width: 479px) {
  .section-block__info {
    row-gap: 17px;
  }
}
.section-block__descr {
  font-size: 16px;
  line-height: 137.5%;
  hyphens: none;
}
@media (max-width: 959px) {
  .section-block__descr {
    width: 80%;
  }
}
@media (max-width: 639px) {
  .section-block__descr {
    width: 100%;
  }
}
.section-block .section-list {
  margin-top: 4px;
}
@media (max-width: 959px) {
  .section-block .section-list {
    width: 72%;
  }
}
@media (max-width: 639px) {
  .section-block .section-list {
    width: 100%;
  }
}
.section-block .try-free-btn {
  margin-left: 4px;
  margin-top: 14px;
}
.section-block .qr-block {
  width: 97%;
  margin-top: auto;
}
@media (max-width: 1199px) {
  .section-block .qr-block {
    width: 90%;
  }
}
@media (max-width: 959px) {
  .section-block .qr-block {
    display: none;
  }
}

.section-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.section-list__item {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.section-list__item svg {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  stroke: #3390ff;
  fill: transparent;
}
.section-list__item span {
  color: #000;
  font-size: 14px;
  line-height: 157.143%;
}

.btn {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  line-height: 18px;
  height: 40px;
  border: 2px solid cornflowerblue;
  border-radius: 8px;
  cursor: pointer;
  text-transform: uppercase;
  color: #fff;
  background-color: cornflowerblue;
  margin: 0;
  padding: 0 30px;
  z-index: 1;
  transition: 0.25s linear;
  transition-property: color, background-color, border-color;
}
@media (hover: hover) {
  .btn:hover {
    background-color: #fff;
    color: cornflowerblue;
  }
}
.btn:active {
  background-color: #fff;
  color: cornflowerblue;
}
.btn.disabled, .btn[disabled] {
  pointer-events: none;
  opacity: 0.5;
}
.btn--secondary {
  color: cornflowerblue;
  background-color: transparent;
}
@media (hover: hover) {
  .btn--secondary:hover {
    color: #fff;
    background-color: cornflowerblue;
  }
}

.btn-inline {
  display: inline-flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0;
}
.btn-inline svg {
  fill: cornflowerblue;
  margin-right: 12px;
}

.burger {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  position: relative;
  width: 42px;
  height: 42px;
}
.burger__line {
  position: absolute;
  width: 14px;
  height: 2px;
  left: 50%;
  transform: translateX(-50%);
  background: #101d50;
}
.burger__line:nth-child(1) {
  top: 35%;
}
.burger__line:nth-child(2) {
  top: 50%;
  transform: translate(-50%);
}
.burger__line:nth-child(3) {
  bottom: 30%;
}

.footer-list {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.footer-list__title {
  color: #363a4a;
  font-size: 14px;
  font-weight: 600;
  line-height: 128.57%;
}
.footer-list__list {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}
.footer-list__link {
  color: #5b6177;
  font-size: 14px;
  font-weight: 400;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.footer-list__link:hover {
  color: #844bfd;
}
.footer-list__item {
  color: #5b6177;
  font-size: 14px;
  font-weight: 400;
  line-height: 128.57%;
}

.footer-address__item {
  display: flex;
  column-gap: 8px;
}
.footer-address__item span {
  color: #5b6177;
  font-size: 14px;
  font-weight: 400;
  line-height: 128.57%;
}
.footer-address__link {
  color: #4779f6;
  font-size: 14px;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.footer-address__link:hover {
  color: #844bfd;
}

.go-top {
  position: fixed;
  bottom: 24px;
  right: 24px;
  opacity: 0;
  visibility: hidden;
}
.go-top.is-active {
  opacity: 1;
  visibility: visible;
}
.go-top:hover svg {
  fill: #844bfd;
}
.go-top svg {
  width: 56px;
  height: 56px;
  stroke: #fff;
  transition: all 0.2s ease-in-out;
}

.input-block {
  position: relative;
  height: 52px;
  width: 100%;
}
.input-block__label {
  z-index: 1;
  position: absolute;
  top: 55%;
  transform: translateY(-50%);
  left: 16px;
  color: #6f7782;
  font-size: 12px;
  font-weight: 600;
  line-height: 133.33%;
  transition: all 0.2s ease;
}
.input-block__input {
  z-index: 2;
  width: 100%;
  height: 100%;
  padding: 12px 16px 0px 16px;
  color: #101d50;
  font-size: 14px;
  font-weight: 400;
  line-height: 114.28%;
  letter-spacing: 1.4px;
  border-radius: 12px;
  border: 1px solid #e8ecee;
  transition: all 0.2s ease-in-out;
}
.input-block__input.error {
  border-color: #fa5959;
}
.input-block__input:focus {
  outline: none;
  border-color: #101d50;
}
.input-block__input:hover {
  background: #f6f5f8;
}
.input-block__input:hover::placeholder {
  visibility: hidden;
}
.input-block__error {
  padding-left: 18px;
  color: #fa5959;
  font-size: 12px;
  line-height: 133.33%;
  background-image: url("/img/icons/error.svg");
  background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  background-position-y: -2px;
}
.input-block__error.is-active {
  visibility: visible;
  opacity: 1;
}

.lang-select {
  position: relative;
}
.lang-select--open .lang-select__box svg {
  transform: rotate(180deg);
}
.lang-select--open .lang-select__options {
  opacity: 1;
  visibility: visible;
}
.lang-select__box {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 12px;
  width: 86px;
  height: 44px;
  border-radius: 10px;
  background: #eeeeee;
  cursor: pointer;
}
.lang-select__box svg {
  fill: none;
  stroke: #101d50;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in-out;
}
.lang-select__selected-option img {
  width: 26px;
}
.lang-select__options {
  list-style: none;
  margin: 0;
  padding: 0;
  z-index: 5;
  top: 110%;
  right: 0;
  width: 200px;
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 10px;
  opacity: 0;
  visibility: hidden;
}
.lang-select__option {
  padding: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}
.lang-select__option:hover {
  background: #f6f5f8;
}
.lang-select__option img {
  width: 26px;
}
.lang-select__option span {
  color: #000;
  font-size: 13px;
  line-height: 153.84%;
}

.lang-select-mob .lang-select__box {
  width: inherit;
  justify-content: space-between;
  padding: 0px 16px;
  height: 56px;
}
.lang-select-mob .lang-select__selected-option span {
  flex-shrink: 0;
  font-size: 16px;
  font-weight: 600;
  margin-left: 8px;
}
.lang-select-mob .lang-select__options {
  margin-top: 24px;
  top: inherit;
  right: inherit;
  width: 100%;
  position: static;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: #fff;
  border-radius: 0;
  opacity: 1;
  visibility: visible;
}
.lang-select-mob .lang-select__option {
  padding: 16px 6px 16px 32px;
  background: #eee;
  border-radius: 12px;
  column-gap: 16px;
}
.lang-select-mob .lang-select__option img {
  width: 38px;
}
.lang-select-mob .lang-select__option svg {
  margin-left: auto;
  width: 24px;
  height: 24px;
  fill: transparent;
  stroke: #101d50;
}

.lang-mob-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}
.lang-mob-modal.is-open {
  display: flex;
  align-items: center;
  justify-content: center;
}
.lang-mob-modal__content {
  overflow: auto;
  width: 500px;
  height: 418px;
  padding: 20px;
  background: #fff;
  border-radius: 24px;
}
@media (max-width: 479px) {
  .lang-mob-modal__content {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
  }
}
.lang-mob-modal__top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0;
}
.lang-mob-modal__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
@media (max-width: 479px) {
  .lang-mob-modal__title {
    font-size: 20px;
  }
}
.lang-mob-modal__logo {
  width: 122px;
}

.loader {
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: conic-gradient(#fff, transparent);
  animation: rotation 1s linear infinite;
  transition: all 0.2s ease-in-out;
}
.loader:before {
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  background: #9C42F5;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transition: all 0.2s ease-in-out;
}
.loader::after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #fff;
  border-radius: 50%;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: all 0.2s ease-in-out;
}
@keyframes rotation {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0);
  }
}

.log-in-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  white-space: nowrap;
  padding: 12px 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  color: #fff;
  border-radius: 12px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all 0.2s ease-in-out;
  user-select: none;
}
@media (max-width: 959px) {
  .log-in-btn {
    padding: 12px 32px;
  }
}
.log-in-btn:hover {
  transform: scale(1.03);
}

.modal-message {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 639px) {
  .modal-message {
    padding-top: 12%;
    align-items: flex-start;
  }
}
@media (max-width: 479px) {
  .modal-message {
    padding: 0;
    align-items: center;
  }
}
.modal-message.is-active {
  visibility: visible;
  opacity: 1;
}
.modal-message__content {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 65%;
  max-height: 288px;
  max-width: 500px;
  padding: 20px;
  background: #fff;
  border-radius: 24px;
  overflow: hidden;
}
@media (max-width: 479px) {
  .modal-message__content {
    width: 100vw;
    border-radius: 0;
    padding-bottom: 84px;
  }
}
.modal-message__top {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modal-message__logo {
  width: 105px;
}
.modal-message__body {
  margin-top: 24px;
  font-size: 20px;
  font-weight: 700;
  line-height: 23px;
  text-align: center;
}
@media (max-width: 639px) {
  .modal-message__body {
    margin-top: 18px;
  }
}
@media (max-width: 479px) {
  .modal-message__body {
    margin-top: 19px;
  }
}
.modal-message__bottom {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.modal-message__close-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 16px 24px;
  padding: 23px;
  width: 100%;
  height: 66px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  color: #fff;
  border-radius: 12px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all 0.2s ease-in-out;
}
@media (max-width: 479px) {
  .modal-message__close-btn {
    padding: 14px;
    height: 56px;
  }
}
.modal-message__close-btn:hover {
  transform: scale(1.02);
}
.modal-message__address-item {
  display: flex;
  justify-content: center;
  column-gap: 8px;
}
.modal-message__address-item span {
  color: #363a4a;
  font-size: 14px;
  line-height: 128.57%;
}
.modal-message__address-link {
  color: #4779f6;
  font-size: 14px;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.modal-message__address-link:hover {
  color: #844bfd;
}

.modal-sign-log {
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
}
@media (max-width: 639px) {
  .modal-sign-log {
    padding-top: 12%;
    align-items: flex-start;
  }
}
@media (max-width: 479px) {
  .modal-sign-log {
    padding: 0;
    align-items: center;
  }
}
.modal-sign-log.is-active {
  visibility: visible;
  opacity: 1;
}
.modal-sign-log__form {
  overflow: auto;
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 65%;
  max-width: 500px;
  padding: 20px;
  background: #fff;
  border-radius: 24px;
}
@media (max-width: 479px) {
  .modal-sign-log__form {
    width: 100vw;
    border-radius: 0;
    padding-bottom: 84px;
  }
}
.modal-sign-log__top {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.modal-sign-log__title {
  font-family: "Roboto", sans-serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 148%;
}
@media (max-width: 639px) {
  .modal-sign-log__title {
    letter-spacing: 1.4px;
  }
}
@media (max-width: 479px) {
  .modal-sign-log__title {
    letter-spacing: normal;
    font-size: 20px;
  }
}
.modal-sign-log__logo {
  width: 105px;
}
.modal-sign-log__body {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
}
@media (max-width: 639px) {
  .modal-sign-log__body {
    margin-top: 18px;
  }
}
@media (max-width: 479px) {
  .modal-sign-log__body {
    margin-top: 19px;
  }
}
.modal-sign-log__code-block {
  margin-top: 20px;
}
.modal-sign-log__bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.modal-sign-log__btns {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
@media (max-width: 639px) {
  .modal-sign-log__btns {
    column-gap: 16px;
    justify-content: space-between;
  }
}
@media (max-width: 479px) {
  .modal-sign-log__btns {
    flex-direction: column-reverse;
    row-gap: 8px;
  }
}
.modal-sign-log__close-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 16px 24px;
  padding: 23px;
  height: 56px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  border-radius: 12px;
  background: #f5f5f5;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 479px) {
  .modal-sign-log__close-btn {
    width: 100%;
    padding: 14px;
    height: 48px;
  }
}
.modal-sign-log__close-btn:hover {
  background-color: #5b6177;
  color: #fff;
}
.modal-sign-log__next-step-btn {
  position: relative;
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 16px 48px;
  padding: 23px;
  height: 56px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 100%;
  border-radius: 12px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all 0.2s ease-in-out;
}
@media (max-width: 479px) {
  .modal-sign-log__next-step-btn {
    padding: 14px;
    height: 48px;
  }
}
.modal-sign-log__next-step-btn:disabled {
  background: #b3bbcc;
  cursor: default;
}
.modal-sign-log__next-step-btn:disabled:hover {
  transform: none;
}
.modal-sign-log__next-step-btn:hover {
  transform: scale(1.03);
}
.modal-sign-log__next-step-btn.is-loading {
  background: linear-gradient(90.05deg, #4579f5 0.05%, #9c42f5 99.96%);
  opacity: 0.5;
}
.modal-sign-log__next-step-btn.is-loading .loader {
  opacity: 1;
  visibility: visible;
}
.modal-sign-log__next-step-btn .loader {
  position: absolute;
  top: 23px;
  right: 23px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.modal-sign-log__personal {
  text-align: center;
}
.modal-sign-log__personal-text {
  font-size: 14px;
  line-height: 18px;
}
.modal-sign-log__personal-link {
  font-size: 14px;
  line-height: 18px;
  color: #4779f6;
  transition: all 0.2s ease-in-out;
}
.modal-sign-log__personal-link:hover {
  color: #844bfd;
}
.modal-sign-log__address-item {
  display: flex;
  justify-content: center;
  column-gap: 8px;
}
.modal-sign-log__address-item span {
  color: #363a4a;
  font-size: 14px;
  line-height: 128.57%;
}
.modal-sign-log__address-link {
  color: #4779f6;
  font-size: 14px;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.modal-sign-log__address-link:hover {
  color: #844bfd;
}

.code-block {
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
}
.code-block__title {
  color: #6f7782;
  font-size: 12px;
  font-weight: 600;
  line-height: 133.33%;
}
.code-block__inputs {
  margin-top: 12px;
  display: flex;
  align-items: center;
  column-gap: 12px;
}
@media (max-width: 479px) {
  .code-block__inputs {
    column-gap: 8px;
    flex-wrap: wrap;
  }
}
.code-block__input {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 52px;
  height: 52px;
  font-size: 20px;
  line-height: 80%;
  border: 1px solid #e8ecee;
  border-radius: 12px;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 639px) {
  .code-block__input {
    width: 42px;
    height: 42px;
  }
}
.code-block__input.error {
  border-color: #fa5959;
}
.code-block__input:focus {
  border-color: #4084f8;
  box-shadow: 0px 0px 0px 2px rgba(64, 132, 248, 0.16);
}
.code-block__input:hover {
  background: #f6f5f8;
}
.code-block__info {
  margin-left: auto;
  padding-right: 9%;
  color: #fa5959;
  font-size: 12px;
  font-weight: 600;
  line-height: 133.33%;
}
@media (max-width: 479px) {
  .code-block__info {
    margin-top: 4px;
    margin-left: 0;
  }
}
.code-block.is-active {
  visibility: visible;
  opacity: 1;
}

.phone-input {
  padding: 0;
  font-size: 14px;
}

.pluses-item {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  column-gap: 14px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 959px) {
  .pluses-item {
    flex-direction: column;
    row-gap: 6px;
  }
}
.pluses-item:hover {
  background: #f6f5f8;
}
.pluses-item:hover .pluses-item__svg {
  opacity: 1;
  visibility: visible;
}
.pluses-item__img {
  width: 60px;
  height: 60px;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 18px;
}
@media (max-width: 639px) {
  .pluses-item__img {
    width: 36px;
    height: 36px;
    border-radius: 12px;
  }
}
.pluses-item__img svg {
  fill: none;
  stroke: #fff;
}
.pluses-item__title {
  width: 62%;
  text-align: left;
  color: #000;
  font-size: 16px;
  font-weight: 400;
  line-height: 112.5%;
  hyphens: none;
  user-select: none;
}
@media (max-width: 959px) {
  .pluses-item__title {
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 639px) {
  .pluses-item__title {
    font-size: 14px;
    line-height: 128.57%;
  }
}
.pluses-item__svg {
  position: absolute;
  width: 24px;
  height: 24px;
  top: 8px;
  right: 8px;
  fill: #28ba48;
  stroke: #28ba48;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in-out;
}

.qr-block {
  position: relative;
  padding: 20px;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  background: #f6f5f8;
  border-radius: 20px;
}
.qr-block__qr-nfc {
  width: fit-content;
  padding: 4px 16px;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  line-height: 150%;
  border-radius: 60px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
}
.qr-block__descr {
  width: 55%;
  font-size: 16px;
  line-height: 137.5%;
}
.qr-block__img {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 250px;
  border-radius: 12px;
}
@media (max-width: 1199px) {
  .qr-block__img {
    width: 200px;
  }
}
@media (max-width: 1199px) {
  .qr-block__img {
    width: 250px;
  }
}
@media (max-width: 639px) {
  .qr-block__img {
    position: static;
  }
}

.section-title {
  margin: 0;
  padding: 0;
  color: #101d50;
  font-size: 48px;
  font-weight: 700;
  line-height: 108.33%;
  user-select: none;
}
@media (max-width: 959px) {
  .section-title {
    font-size: 36px;
    line-height: 116.66%;
  }
}
@media (max-width: 479px) {
  .section-title {
    font-size: 20px;
    line-height: 23.2px;
  }
}

.sign-in-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  white-space: nowrap;
  color: #101d50;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  transition: all 0.2s ease-in-out;
  user-select: none;
}
.sign-in-btn:hover {
  color: #844bfd;
}

.textarea-block {
  position: relative;
  height: 72px;
  width: 100%;
}
.textarea-block__label {
  position: absolute;
  top: 17%;
  left: 16px;
  color: #6f7782;
  font-size: 12px;
  font-weight: 600;
  line-height: 133.33%;
  transition: all 0.2s ease;
}
.textarea-block__textarea {
  width: 100%;
  height: 100%;
  padding: 16px 16px 0px 16px;
  color: #101d50;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 114.28%;
  border-radius: 12px;
  border: 1px solid #e8ecee;
  transition: all 0.2s ease-in-out;
  resize: none;
}
.textarea-block__textarea.error {
  border-color: #fa5959;
}
.textarea-block__textarea:focus {
  outline: none;
  border-color: #101d50;
}
.textarea-block__textarea:hover {
  background: #f6f5f8;
}
.textarea-block__error {
  padding-left: 18px;
  color: #fa5959;
  font-size: 12px;
  line-height: 133.33%;
  background-image: url("/img/icons/error.svg");
  background-repeat: no-repeat;
  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  background-position-y: -2px;
}
.textarea-block__error.is-active {
  visibility: visible;
  opacity: 1;
}

.try-free-btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.try-free-btn:hover span {
  color: #844bfd;
}
.try-free-btn:hover svg {
  stroke: #844bfd;
  fill: #844bfd;
  transform: translateX(10px);
}
.try-free-btn span {
  color: #4779f6;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  transition: all 0.2s ease-in-out;
}
.try-free-btn svg {
  width: 19px;
  height: 19px;
  stroke: #4779f6;
  fill: #4779f6;
  transition: all 0.2s ease-in-out;
}

.base-objects__container {
  width: 91%;
  display: flex;
  justify-content: center;
}
@media (max-width: 1199px) {
  .base-objects__container {
    width: 100%;
    justify-content: flex-start;
  }
}
.base-objects .section-block {
  flex-direction: row-reverse;
  padding-left: 100px;
  column-gap: 6%;
}
@media (max-width: 1199px) {
  .base-objects .section-block {
    padding-left: 0px;
    width: 100%;
    position: relative;
    flex-direction: row;
    width: 100%;
  }
}
@media (max-width: 639px) {
  .base-objects .section-block {
    flex-direction: column-reverse;
  }
}
.base-objects .section-block__img {
  width: 52%;
}
@media (min-width: 1199px) {
  .base-objects .section-block__img {
    transform: translateY(100%);
    opacity: 0;
  }
}
@media (max-width: 1199px) {
  .base-objects .section-block__img {
    position: absolute;
    top: -4%;
    right: -2%;
    width: 49%;
    max-width: 480px;
  }
}
@media (max-width: 959px) {
  .base-objects .section-block__img {
    top: -5%;
    right: -7%;
    width: 55%;
    max-width: 344px;
  }
}
@media (max-width: 639px) {
  .base-objects .section-block__img {
    position: static;
    width: 74%;
    margin: 0 auto;
    margin-top: 26px;
  }
}
@media (max-width: 479px) {
  .base-objects .section-block__img {
    margin-top: 22px;
    width: 97%;
  }
}
.base-objects .section-block__info {
  padding-top: 57px;
  width: 50%;
}
@media (max-width: 1199px) {
  .base-objects .section-block__info {
    width: 45%;
  }
}
@media (max-width: 959px) {
  .base-objects .section-block__info {
    width: 68%;
    padding-top: 0;
  }
}
@media (max-width: 639px) {
  .base-objects .section-block__info {
    width: 92%;
  }
}
@media (max-width: 479px) {
  .base-objects .section-block__info {
    width: 100%;
  }
}
.base-objects .section-block__descr {
  width: 72%;
}
@media (max-width: 1199px) {
  .base-objects .section-block__descr {
    width: 75%;
  }
}
@media (max-width: 959px) {
  .base-objects .section-block__descr {
    width: 48%;
  }
}
@media (max-width: 639px) {
  .base-objects .section-block__descr {
    width: 74%;
  }
}
.base-objects .section-block .section-list {
  width: 95%;
  margin-top: 10px;
}
@media (max-width: 1199px) {
  .base-objects .section-block .section-list {
    margin-top: 13px;
  }
}
@media (max-width: 959px) {
  .base-objects .section-block .section-list {
    margin-top: 5px;
    width: 70%;
  }
}
@media (max-width: 479px) {
  .base-objects .section-block .section-list {
    width: 100%;
  }
}
.base-objects .section-block button {
  margin-top: 20px;
}
@media (max-width: 959px) {
  .base-objects .section-block button {
    margin-top: 15px;
  }
}
@media (max-width: 479px) {
  .base-objects .section-block button {
    margin-top: 12px;
    margin-right: auto;
    margin-left: auto;
  }
}

.brends__container {
  padding: 0 110px;
  position: relative;
}
@media (max-width: 1199px) {
  .brends__container {
    padding: 0px 86px;
  }
}
@media (max-width: 959px) {
  .brends__container {
    padding: 0 10px;
  }
}
@media (max-width: 479px) {
  .brends__container {
    width: 100%;
    overflow: hidden;
    padding: 0 8px;
  }
}

.brends-swiper {
  width: 100%;
  overflow: hidden;
}
@media (max-width: 479px) {
  .brends-swiper {
    height: 230px;
  }
}
.brends-swiper__wrapper {
  height: 100px;
  display: flex;
  align-items: center;
}
@media (max-width: 479px) {
  .brends-swiper__wrapper {
    height: 100%;
  }
}
.brends-swiper__slide {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 479px) {
  .brends-swiper__slide {
    margin-top: 0 !important;
    height: calc((100% - 30px) / 2);
  }
}
.brends-swiper__slide img {
  filter: grayscale(1);
  transition: all 0.2s ease-in-out;
}
.brends-swiper__slide img:hover {
  filter: grayscale(0);
}
@media (max-width: 479px) {
  .brends-swiper__slide img {
    max-height: 100%;
  }
}

.swiper-brend-next,
.swiper-brend-prev {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f6f5f8;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 959px) {
  .swiper-brend-next,
  .swiper-brend-prev {
    display: none;
  }
}
.swiper-brend-next:hover,
.swiper-brend-prev:hover {
  background: #844bfd;
}
.swiper-brend-next:hover svg,
.swiper-brend-prev:hover svg {
  stroke: #fff;
}
.swiper-brend-next svg,
.swiper-brend-prev svg {
  width: 24px;
  height: 24px;
  fill: none;
  stroke: #101d50;
  transition: all 0.2s ease-in-out;
}

.swiper-brend-next {
  right: 14px;
}

.swiper-brend-prev {
  left: 14px;
}

.swiper-slide-2 img {
  width: 65%;
}

.swiper-slide-3 img {
  width: 60%;
}

.swiper-slide-5 img {
  width: 75%;
}

.swiper-slide-12 img {
  width: 80%;
}

.swiper-slide-13 img {
  width: 40%;
}

.burger-menu-container {
  overflow: auto;
  z-index: 8;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  display: none;
}
@media (max-width: 959px) {
  .burger-menu-container {
    display: block;
  }
}
.burger-menu-container.is-open {
  opacity: 1;
  visibility: visible;
}
.burger-menu-container.is-open .burger-menu {
  opacity: 1;
  visibility: visible;
  transform: none;
}

.burger-menu {
  z-index: 9;
  position: absolute;
  top: 0;
  left: 0;
  width: 74vw;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 24px;
  padding-bottom: 84px;
  background: #f6f5f8;
  transform: translateX(-100%);
  opacity: 0;
  visibility: hidden;
}
@media (max-width: 639px) {
  .burger-menu {
    width: 81vw;
  }
}
@media (max-width: 479px) {
  .burger-menu {
    width: 72vw;
    padding: 24px 16px;
    padding-bottom: 84px;
  }
}
.burger-menu__logo {
  width: 122px;
}
.burger-menu__btns {
  margin-bottom: 16px;
  position: absolute;
  top: 24px;
  right: 24px;
}
@media (max-width: 479px) {
  .burger-menu__btns {
    margin-top: 26px;
    position: static;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
.burger-menu__bottom {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}
@media (max-width: 479px) {
  .burger-menu__bottom {
    row-gap: 16px;
  }
}
.burger-menu .burger-log-in-btn.burger-log-in-btn {
  display: none;
}
@media (max-width: 479px) {
  .burger-menu .burger-log-in-btn.burger-log-in-btn {
    display: block;
    text-align: center;
  }
}
.burger-menu .burger-sign-in-btn.burger-sign-in-btn {
  display: block;
  text-align: center;
  padding: 12px 24px;
  border: 1px solid #101d50;
  border-radius: 12px;
}
.burger-menu .burger-sign-in-btn.burger-sign-in-btn:hover {
  border-color: #844bfd;
}
.burger-menu .burger-lang-select.burger-lang-select {
  display: block;
  width: 100%;
}

.burger-menu-nav {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;
  display: none;
}
@media (max-width: 639px) {
  .burger-menu-nav {
    margin-top: 30px;
    row-gap: 53px;
  }
}
@media (max-width: 479px) {
  .burger-menu-nav {
    margin-top: 22px;
    row-gap: 22px;
  }
}
.burger-menu-nav__link {
  font-size: 16px;
  font-weight: 600;
  line-height: 18px;
  transition: all 0.2s ease-in-out;
}
.burger-menu-nav__link:hover {
  color: #844bfd;
}
.burger-menu-nav__link:active {
  color: #844bfd;
}

.burger-menu-nets {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
@media (max-width: 479px) {
  .burger-menu-nets {
    margin-top: -8px;
  }
}
.burger-menu-nets__link {
  width: 26px;
  height: 26px;
}

.comfort-pluses__container {
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 42px;
}
@media (max-width: 959px) {
  .comfort-pluses__container {
    row-gap: 20px;
  }
}
@media (max-width: 639px) {
  .comfort-pluses__container {
    row-gap: 26px;
  }
}
@media (max-width: 479px) {
  .comfort-pluses__container {
    row-gap: 18px;
  }
}
.comfort-pluses .section-title {
  width: 80%;
  font-size: 36px;
  line-height: 133.33%;
  text-align: center;
}
@media (max-width: 1199px) {
  .comfort-pluses .section-title {
    width: 100%;
  }
}
@media (max-width: 959px) {
  .comfort-pluses .section-title {
    font-size: 32px;
  }
}
@media (max-width: 639px) {
  .comfort-pluses .section-title {
    width: 95%;
    font-size: 27px;
    line-height: 155.55%;
  }
}
@media (max-width: 479px) {
  .comfort-pluses .section-title {
    font-size: 18px;
    line-height: 20px;
  }
}
.comfort-pluses__grid {
  width: 65%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}
@media (max-width: 1199px) {
  .comfort-pluses__grid {
    width: 81%;
  }
}
@media (max-width: 959px) {
  .comfort-pluses__grid {
    margin-right: 20px;
    width: 97%;
  }
}
@media (max-width: 639px) {
  .comfort-pluses__grid {
    width: 94%;
    margin-right: 0;
    row-gap: 2px;
  }
}
@media (max-width: 479px) {
  .comfort-pluses__grid {
    grid-template-columns: 1fr 1fr;
  }
  .comfort-pluses__grid .pluses-item:last-child {
    grid-column: 1/-1;
  }
}

.connect-now {
  font-size: 0;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
}
.connect-now__container {
  padding-left: 100px;
  display: flex;
  align-items: center;
  column-gap: 6%;
}
@media (max-width: 1199px) {
  .connect-now__container {
    padding-left: 30px;
  }
}
@media (max-width: 1199px) {
  .connect-now__container {
    width: 100%;
    position: relative;
    padding: 61px 0;
  }
}
@media (max-width: 959px) {
  .connect-now__container {
    flex-direction: column;
    padding: 16px 0;
    padding-bottom: 0;
  }
}
.connect-now__info {
  width: 64%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}
@media (max-width: 1199px) {
  .connect-now__info {
    width: 36%;
  }
}
@media (max-width: 959px) {
  .connect-now__info {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .connect-now__info {
    align-items: center;
    row-gap: 16px;
  }
}
.connect-now__img {
  max-height: 100%;
  width: 125%;
}
@media (max-width: 1199px) {
  .connect-now__img {
    position: absolute;
    bottom: 0;
    right: -12%;
    width: 70%;
    max-width: 660px;
  }
}
@media (max-width: 959px) {
  .connect-now__img {
    position: static;
    width: 100%;
    margin-top: 30px;
  }
}
@media (max-width: 639px) {
  .connect-now__img {
    margin-top: 16px;
  }
}
@media (max-width: 479px) {
  .connect-now__img {
    margin-top: 39px;
  }
}
.connect-now__promo {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  width: fit-content;
  padding: 6px 14px;
  border-radius: 84px;
  background-color: #fff;
  transition: all 0.2s ease-in-out;
}
.connect-now__promo:hover {
  transform: scale(1.02);
}
@media (max-width: 639px) {
  .connect-now__promo {
    display: none;
  }
}
.connect-now__promo span {
  background-image: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 700;
  line-height: 114.28%;
}
.connect-now__title {
  color: #f6f5f8;
  font-size: 36px;
  font-weight: 700;
  line-height: 111.111%;
}
@media (max-width: 959px) {
  .connect-now__title {
    font-size: 32px;
    line-height: 127%;
  }
}
@media (max-width: 639px) {
  .connect-now__title {
    width: 60%;
    text-align: center;
  }
}
@media (max-width: 479px) {
  .connect-now__title {
    font-size: 20px;
    line-height: 23px;
  }
}
.connect-now__descr {
  color: #fff;
  font-size: 16px;
  line-height: 137.5%;
  hyphens: none;
}
@media (max-width: 959px) {
  .connect-now__descr {
    width: 55%;
  }
}
@media (max-width: 639px) {
  .connect-now__descr {
    width: 75%;
    text-align: center;
  }
}
@media (max-width: 479px) {
  .connect-now__descr {
    width: 100%;
  }
}

.control-devices {
  background: #f6f5f8;
  font-size: 0;
}
@media (max-width: 959px) {
  .control-devices {
    padding-bottom: 10px;
  }
}
@media (max-width: 639px) {
  .control-devices {
    padding-bottom: 6px;
  }
}
@media (max-width: 479px) {
  .control-devices {
    padding-bottom: 4px;
  }
}
.control-devices .section-big-block .section-title {
  width: 70%;
  text-align: center;
}
@media (max-width: 1199px) {
  .control-devices .section-big-block .section-title {
    width: 80%;
  }
}
@media (max-width: 959px) {
  .control-devices .section-big-block .section-title {
    width: 90%;
  }
}
@media (max-width: 639px) {
  .control-devices .section-big-block .section-title {
    width: 100%;
    text-align: start;
  }
}
.control-devices .section-big-block__descr {
  margin-top: 26px;
  width: 70%;
}
@media (max-width: 959px) {
  .control-devices .section-big-block__descr {
    width: 90%;
  }
}
@media (max-width: 479px) {
  .control-devices .section-big-block__descr {
    margin-top: 17px;
    width: 100%;
  }
}
.control-devices .section-big-block__list {
  row-gap: 10px;
}
@media (max-width: 959px) {
  .control-devices .section-big-block__list {
    row-gap: 2px;
  }
}
@media (max-width: 639px) {
  .control-devices .section-big-block__list {
    margin-top: 19px;
    width: 100%;
    row-gap: 18px;
  }
}
@media (max-width: 479px) {
  .control-devices .section-big-block__list {
    margin-top: 22px;
    row-gap: 20px;
  }
}
.control-devices .section-big-block__img {
  width: 69%;
  margin-top: 60px;
}
@media (min-width: 1199px) {
  .control-devices .section-big-block__img {
    opacity: 0;
    transform: scale(0.5);
  }
}
@media (max-width: 1199px) {
  .control-devices .section-big-block__img {
    margin-top: 32px;
  }
}
@media (max-width: 959px) {
  .control-devices .section-big-block__img {
    width: 95%;
    margin-top: 38px;
  }
}
@media (max-width: 639px) {
  .control-devices .section-big-block__img {
    margin-left: 9px;
    width: 96%;
  }
}
@media (max-width: 479px) {
  .control-devices .section-big-block__img {
    margin-top: 45px;
    margin-left: 7px;
  }
}

.control-mistakes__container {
  display: flex;
  justify-content: center;
}
.control-mistakes .section-block {
  align-items: center;
  padding-left: 81px;
  column-gap: 11%;
}
@media (max-width: 1199px) {
  .control-mistakes .section-block {
    padding-left: 0px;
    column-gap: 2%;
  }
}
@media (max-width: 959px) {
  .control-mistakes .section-block {
    position: relative;
    align-items: flex-end;
    justify-content: flex-end;
    flex-direction: row-reverse;
  }
}
@media (max-width: 639px) {
  .control-mistakes .section-block {
    align-items: center;
    flex-direction: column-reverse;
  }
}
.control-mistakes .section-block__img {
  width: 28%;
}
@media (min-width: 1199px) {
  .control-mistakes .section-block__img {
    transform: translateY(100%);
    opacity: 0;
  }
}
@media (max-width: 1199px) {
  .control-mistakes .section-block__img {
    width: 32%;
    max-width: 320px;
  }
}
@media (max-width: 959px) {
  .control-mistakes .section-block__img {
    position: absolute;
    top: 27%;
    width: 35%;
    right: 7%;
    max-width: 220px;
  }
}
@media (max-width: 639px) {
  .control-mistakes .section-block__img {
    position: static;
    margin-top: 25px;
    width: 48%;
  }
}
@media (max-width: 479px) {
  .control-mistakes .section-block__img {
    width: 73%;
    margin-top: 18px;
  }
}
.control-mistakes .section-block__info {
  margin-top: -12px;
  width: 56%;
  gap: 0;
}
@media (max-width: 1199px) {
  .control-mistakes .section-block__info {
    width: 60%;
  }
}
@media (max-width: 959px) {
  .control-mistakes .section-block__info {
    width: 73%;
    flex-shrink: 0;
  }
}
@media (max-width: 639px) {
  .control-mistakes .section-block__info {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .control-mistakes .section-block .section-title {
    width: 95%;
  }
}
@media (max-width: 479px) {
  .control-mistakes .section-block .section-title {
    width: 80%;
  }
}
.control-mistakes .section-block__descr {
  margin-top: 15px;
  width: 80%;
}
@media (max-width: 959px) {
  .control-mistakes .section-block__descr {
    margin-top: 24px;
    width: 66%;
  }
}
@media (max-width: 639px) {
  .control-mistakes .section-block__descr {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .control-mistakes .section-block__descr {
    margin-top: 16px;
  }
}
.control-mistakes .section-block .section-list {
  margin-top: 20px;
}
@media (max-width: 959px) {
  .control-mistakes .section-block .section-list {
    margin-top: 30px;
    width: 65%;
  }
}
@media (max-width: 479px) {
  .control-mistakes .section-block .section-list {
    width: 100%;
    margin-top: 22px;
  }
}
.control-mistakes .section-block .try-free-btn {
  margin-top: 30px;
}
@media (max-width: 959px) {
  .control-mistakes .section-block .try-free-btn {
    margin-top: 38px;
  }
}
@media (max-width: 479px) {
  .control-mistakes .section-block .try-free-btn {
    margin-top: 29px;
  }
}

.footer {
  padding: 82px 100px;
  background: #f6f5f8;
}
@media (max-width: 1199px) {
  .footer {
    padding: 40px 50px;
  }
}
@media (max-width: 1199px) {
  .footer {
    padding: 82px 0;
  }
}
@media (max-width: 479px) {
  .footer {
    padding: 24px 0;
  }
}
.footer__container {
  display: flex;
  flex-direction: column;
  row-gap: 44px;
}
@media (max-width: 639px) {
  .footer__container {
    row-gap: 47px;
  }
}
@media (max-width: 479px) {
  .footer__container {
    row-gap: 31px;
  }
}

.footer-top {
  padding-right: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 959px) {
  .footer-top {
    padding: 0 58px 0 10px;
  }
}
@media (max-width: 639px) {
  .footer-top {
    padding: 0;
    flex-wrap: wrap;
    row-gap: 32px;
  }
}
@media (max-width: 479px) {
  .footer-top {
    padding-left: 10px;
    row-gap: 17px;
  }
}
.footer-top__logo {
  width: 180px;
}
.footer-top__btns {
  display: flex;
  column-gap: 12px;
  align-items: center;
}
@media (max-width: 959px) {
  .footer-top__btns {
    margin-top: -6px;
  }
}
@media (max-width: 639px) {
  .footer-top__btns {
    width: 100%;
  }
}
.footer-top__btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 8px 16px;
  font-size: 14px;
  line-height: 128.57%;
  border-radius: 8px;
  border: 1px solid #101d50;
  background: transparent;
  transition: all 0.2s ease-in-out;
}
.footer-top__btn:hover {
  border-color: #844bfd;
  color: #844bfd;
}
.footer-top .log-in-btn {
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  line-height: 128.57%;
  border-radius: 8px;
}

.footer-main {
  width: 89%;
  display: flex;
  justify-content: space-between;
}
@media (max-width: 959px) {
  .footer-main {
    flex-wrap: wrap;
    width: 84%;
    padding-left: 12px;
    row-gap: 48px;
  }
}
@media (max-width: 639px) {
  .footer-main {
    padding-left: 0;
  }
}
@media (max-width: 479px) {
  .footer-main {
    padding-left: 10px;
    row-gap: 32px;
  }
}
.footer-main .footer-address {
  width: 26%;
}
@media (max-width) {
  .footer-main .footer-address {
    width: 27%;
  }
}
@media (max-width: 959px) {
  .footer-main .footer-address {
    width: 100%;
    order: 2;
  }
}

.footer-bottom {
  width: fit-content;
  display: flex;
  column-gap: 12px;
}
@media (max-width: 959px) {
  .footer-bottom {
    padding-left: 10px;
  }
}
@media (max-width: 639px) {
  .footer-bottom {
    padding-left: 0;
  }
}
@media (max-width: 479px) {
  .footer-bottom {
    padding-left: 10px;
  }
}

.footer-net-link {
  font-size: 0;
  width: 26px;
  height: 26px;
}
.footer-net-link:hover svg {
  fill: #844bfd;
}
.footer-net-link:active svg {
  fill: #844bfd;
}
.footer-net-link svg {
  width: inherit;
  height: inherit;
  fill: #101d50;
  transition: all 0.2s ease-in-out;
}

.link-digitask.link-digitask:hover svg {
  fill: #fe5840;
}
.link-digitask.link-digitask:active svg {
  fill: #fe5840;
}

.header {
  background: #f6f5f8;
}
.header__container {
  padding: 24px 4px;
  display: flex;
  align-items: center;
  column-gap: 32px;
}
@media (max-width: 1199px) {
  .header__container {
    padding: 24px 14px;
    column-gap: 32px;
  }
}
@media (max-width: 959px) {
  .header__container {
    justify-content: space-between;
  }
}
@media (max-width: 639px) {
  .header__container {
    padding: 24px 8px;
  }
}
@media (max-width: 479px) {
  .header__container {
    flex-direction: row-reverse;
    padding: 24px 13px 24px 15px;
  }
}
.header .burger {
  display: none;
}
@media (max-width: 959px) {
  .header .burger {
    display: block;
    margin-right: 12%;
  }
}
@media (max-width: 639px) {
  .header .burger {
    margin-right: 17%;
  }
}
@media (max-width: 479px) {
  .header .burger {
    margin-right: 0;
  }
}
.header__logo {
  max-width: 122px;
}
@media (max-width: 479px) {
  .header__logo {
    max-width: 104px;
  }
}
@media (max-width: 959px) {
  .header .sign-in-btn {
    display: none;
  }
}
@media (max-width: 479px) {
  .header .log-in-btn {
    display: none;
  }
}
@media (max-width: 959px) {
  .header .lang-select {
    display: none;
  }
}

.header-nav {
  width: 100%;
}
@media (max-width: 959px) {
  .header-nav {
    display: none;
  }
}
.header-nav__list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  column-gap: 32px;
  display: none;
}
@media (max-width: 1199px) {
  .header-nav__list {
    column-gap: 27px;
  }
}
.header-nav__link {
  color: #101d50;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  transition: all 0.2s ease-in-out;
  user-select: none;
  hyphens: none;
}
.header-nav__link:hover {
  color: #844bfd;
}

.limboo-logo {
  max-width: 180px;
}
@media (max-width: 479px) {
  .limboo-logo {
    max-width: 130px;
  }
}

.hero {
  background: #f6f5f8;
}
@media (max-width: 959px) {
  .hero {
    padding-top: 28px;
  }
}
@media (max-width: 639px) {
  .hero {
    padding-top: 25px;
  }
}
@media (max-width: 479px) {
  .hero {
    padding-top: 30px;
  }
}
.hero__container {
  position: relative;
  width: 100%;
  display: flex;
}
@media (max-width: 959px) {
  .hero__container {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 479px) {
  .hero__container {
    align-items: center;
  }
}
.hero__left {
  position: relative;
  z-index: 3;
  padding: 46px 100px 64px;
  width: 64%;
}
@media (max-width: 1199px) {
  .hero__left {
    padding: 46px 0px 64px;
    width: 54%;
  }
}
@media (max-width: 959px) {
  .hero__left {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
}
.hero__right {
  z-index: 2;
  position: absolute;
  bottom: 0;
  right: -2%;
  height: 100%;
  width: 60%;
}
@media (max-width: 959px) {
  .hero__right {
    overflow: hidden;
    position: relative;
    right: 0;
    width: 100%;
    margin-top: 50px;
  }
}
@media (max-width: 639px) {
  .hero__right {
    width: 100vw;
    right: 8px;
  }
}
@media (max-width: 479px) {
  .hero__right {
    max-height: 230px;
    margin-top: 20px;
    margin-left: 16px;
    width: 80%;
  }
}
.hero__title {
  color: #101d50;
  font-size: 48px;
  font-weight: 700;
  line-height: 108.33%;
}
@media (max-width: 959px) {
  .hero__title {
    font-size: 36px;
    width: 56%;
  }
}
@media (max-width: 639px) {
  .hero__title {
    font-size: 24px;
    line-height: 133.33%;
    width: 53%;
  }
}
@media (max-width: 479px) {
  .hero__title {
    width: 80%;
  }
}
.hero__descr {
  margin-top: 14px;
  width: 75%;
  hyphens: none;
  color: #101d50;
  font-size: 16px;
  font-weight: 400;
  line-height: 137.5%;
}
@media (max-width: 959px) {
  .hero__descr {
    width: 60%;
    margin-top: 20px;
  }
}
@media (max-width: 639px) {
  .hero__descr {
    width: 80%;
    margin-top: 17px;
  }
}
@media (max-width: 479px) {
  .hero__descr {
    margin-top: 34px;
  }
}
.hero__tabs.hero__tabs {
  margin-top: 32px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 8px;
  row-gap: 8px;
}
@media (max-width: 959px) {
  .hero__tabs.hero__tabs {
    justify-content: center;
    margin-top: 18px;
    width: 81%;
  }
}
@media (max-width: 639px) {
  .hero__tabs.hero__tabs {
    width: 100%;
  }
}
@media (max-width: 479px) {
  .hero__tabs.hero__tabs {
    display: none;
  }
}
.hero__btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 12px 16px 12px 24px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  border-radius: 60px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all 0.2s ease-in-out;
  margin-top: 60px;
  box-shadow: 0px 0px 41px 0px rgba(147, 73, 246, 0.45);
}
.hero__btn svg {
  width: 24px;
  height: 24px;
  stroke: #fff;
  fill: #fff;
  transition: all 0.2s ease-in-out;
}
.hero__btn:hover {
  transform: scale(1.05);
}
.hero__btn:hover svg {
  transform: translateX(8px);
}
@media (max-width: 959px) {
  .hero__btn {
    margin-top: 20px;
  }
}
.hero .swiper-pagination-bullet.swiper-pagination-bullet {
  margin: 0;
  width: fit-content;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 14px 6px 29px;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 128.57%;
  border-radius: 10px;
  background: #fff;
  background-image: url("/img/icons/tip.svg");
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.16);
  border: 1px solid transparent;
  user-select: none;
  opacity: 1;
  transition: all 0.3s ease-in-out;
}
@media (max-width: 639px) {
  .hero .swiper-pagination-bullet.swiper-pagination-bullet {
    height: 26px;
    padding: 6px 8px 6px 24px;
    background-size: 12px 12px;
    background-position-x: 6px;
    border-radius: 8px;
  }
}
.hero .swiper-pagination-bullet.swiper-pagination-bullet:hover {
  border-color: #844bfd;
}
.hero .swiper-pagination-bullet.swiper-pagination-bullet:active {
  transform: scale(0.95);
}
.hero .swiper-pagination-bullet.swiper-pagination-bullet-active {
  border-color: #3390ff;
}
@media (max-width: 959px) {
  .hero .swiper-wrapper {
    display: flex;
    align-items: flex-end;
  }
}
@media (max-width: 479px) {
  .hero .swiper-wrapper {
    max-height: inherit;
  }
}

.hero-tab {
  font-size: 0;
}
@media (max-width: 959px) {
  .hero-tab {
    max-height: 445px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
}
@media (max-width: 479px) {
  .hero-tab {
    max-height: 229px;
  }
}
.hero-tab picture {
  position: absolute;
  bottom: 0;
  max-height: 100%;
}
@media (max-width: 959px) {
  .hero-tab picture {
    position: static;
    max-height: inherit;
    object-fit: cover;
    object-position: top;
  }
}

.hero-tab-1 {
  overflow-y: hidden;
}
.hero-tab-1__img {
  width: 474px;
  left: 26%;
}
@media (max-width: 1199px) {
  .hero-tab-1__img {
    left: 27%;
  }
}
.hero-tab-2__img {
  width: 694px;
  left: 18%;
}

.hero-tab-3 {
  overflow-y: hidden;
}
.hero-tab-3__img {
  width: 480px;
  left: 26%;
}

.hero-tab-4__img {
  width: 720px;
  left: 16%;
}

.hero-tab-5__img {
  width: 644px;
  left: 24%;
}

.hero-tab-6__img {
  width: 660px;
  left: 4%;
}

.main {
  overflow: hidden;
}
.main .brends {
  margin-top: 58px;
}
@media (max-width: 1199px) {
  .main .brends {
    margin-top: 28px;
  }
}
@media (max-width: 959px) {
  .main .brends {
    margin-top: 20px;
  }
}
@media (max-width: 639px) {
  .main .brends {
    margin-top: 10px;
  }
}
@media (max-width: 479px) {
  .main .brends {
    margin-top: 50px;
  }
}
.main .comfort-pluses {
  margin-top: 78px;
}
@media (max-width: 1199px) {
  .main .comfort-pluses {
    margin-top: 30px;
  }
}
@media (max-width: 959px) {
  .main .comfort-pluses {
    margin-top: 20px;
  }
}
@media (max-width: 479px) {
  .main .comfort-pluses {
    margin-top: 18px;
  }
}
.main .services {
  margin-top: 80px;
}
@media (max-width: 1199px) {
  .main .services {
    margin-top: 64px;
  }
}
@media (max-width: 959px) {
  .main .services {
    margin-top: 42px;
  }
}
@media (max-width: 639px) {
  .main .services {
    margin-top: 32px;
  }
}
@media (max-width: 479px) {
  .main .services {
    margin-top: 61px;
  }
}
.main .planing-works {
  margin-top: 126px;
}
@media (max-width: 1199px) {
  .main .planing-works {
    margin-top: 92px;
  }
}
@media (max-width: 959px) {
  .main .planing-works {
    margin-top: 42px;
  }
}
@media (max-width: 639px) {
  .main .planing-works {
    margin-top: 32px;
  }
}
@media (max-width: 479px) {
  .main .planing-works {
    margin-top: 62px;
  }
}
.main .mob-app {
  margin-top: 86px;
}
@media (max-width: 1199px) {
  .main .mob-app {
    margin-top: 158px;
  }
}
@media (max-width: 959px) {
  .main .mob-app {
    margin-top: 52px;
  }
}
@media (max-width: 639px) {
  .main .mob-app {
    margin-top: 28px;
  }
}
@media (max-width: 479px) {
  .main .mob-app {
    margin-top: 58px;
  }
}
.main .base-objects {
  margin-top: 82px;
}
@media (max-width: 959px) {
  .main .base-objects {
    margin-top: 89px;
  }
}
@media (max-width: 639px) {
  .main .base-objects {
    margin-top: 33px;
  }
}
@media (max-width: 479px) {
  .main .base-objects {
    margin-top: 63px;
  }
}
.main .mobile-check-lists {
  margin-top: 76px;
}
@media (max-width: 1199px) {
  .main .mobile-check-lists {
    margin-top: 298px;
  }
}
@media (max-width: 959px) {
  .main .mobile-check-lists {
    margin-top: 88px;
  }
}
@media (max-width: 639px) {
  .main .mobile-check-lists {
    margin-top: 26px;
  }
}
@media (max-width: 479px) {
  .main .mobile-check-lists {
    margin-top: 56px;
  }
}
.main .schedule-staff {
  margin-top: 80px;
}
@media (max-width: 1199px) {
  .main .schedule-staff {
    margin-top: 62px;
  }
}
@media (max-width: 959px) {
  .main .schedule-staff {
    margin-top: 52px;
  }
}
@media (max-width: 639px) {
  .main .schedule-staff {
    margin-top: 43px;
  }
}
@media (max-width: 479px) {
  .main .schedule-staff {
    margin-top: 73px;
  }
}
.main .control-mistakes {
  margin-top: 74px;
}
@media (max-width: 1199px) {
  .main .control-mistakes {
    margin-top: 180px;
  }
}
@media (max-width: 959px) {
  .main .control-mistakes {
    margin-top: 50px;
  }
}
@media (max-width: 639px) {
  .main .control-mistakes {
    margin-top: 41px;
  }
}
@media (max-width: 479px) {
  .main .control-mistakes {
    margin-top: 70px;
  }
}
.main .control-devices {
  margin-top: 70px;
}
@media (max-width: 1199px) {
  .main .control-devices {
    margin-top: 54px;
  }
}
@media (max-width: 959px) {
  .main .control-devices {
    margin-top: 139px;
  }
}
@media (max-width: 639px) {
  .main .control-devices {
    margin-top: 26px;
  }
}
@media (max-width: 479px) {
  .main .control-devices {
    margin-top: 56px;
  }
}

.mob-app {
  min-height: 639px;
  position: relative;
  background: #f6f5f8;
}
@media (max-width: 1199px) {
  .mob-app {
    min-height: 560px;
  }
}
@media (max-width: 959px) {
  .mob-app {
    min-height: 464px;
  }
}
@media (max-width: 639px) {
  .mob-app {
    min-height: 583px;
  }
}
@media (max-width: 479px) {
  .mob-app {
    min-height: 677px;
  }
}
.mob-app__container {
  padding-top: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 1199px) {
  .mob-app__container {
    padding-top: 80px;
  }
}
@media (max-width: 959px) {
  .mob-app__container {
    padding-top: 35px;
  }
}
@media (max-width: 639px) {
  .mob-app__container {
    align-items: flex-start;
  }
}
@media (max-width: 479px) {
  .mob-app__container {
    padding-top: 32px;
  }
}
.mob-app .section-title {
  text-align: center;
  width: 60%;
}
@media (max-width: 639px) {
  .mob-app .section-title {
    text-align: left;
    width: 80%;
  }
}
.mob-app .section-block__img {
  position: absolute;
  top: 114px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0;
  width: 1290px;
  display: flex;
  justify-content: center;
}
@media (min-width: 1199px) {
  .mob-app .section-block__img {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
}
@media (max-width: 1199px) {
  .mob-app .section-block__img {
    top: 96px;
    width: 1140px;
  }
}
@media (max-width: 959px) {
  .mob-app .section-block__img {
    top: 130px;
    width: 820px;
  }
}
@media (max-width: 639px) {
  .mob-app .section-block__img {
    width: 565px;
    top: 108px;
    left: inherit;
    right: -42px;
    transform: translateX(0);
  }
}
@media (max-width: 479px) {
  .mob-app .section-block__img {
    margin: 0 auto;
    width: 400px;
    top: 257px;
    right: inherit;
    left: 50%;
    transform: translateX(-50%);
  }
}
.mob-app .section-big-block__descr {
  margin-top: 24px;
  font-size: 16px;
  font-style: normal;
  line-height: 137.5%;
}
@media (max-width: 1199px) {
  .mob-app .section-big-block__descr {
    width: 36%;
  }
}
@media (max-width: 959px) {
  .mob-app .section-big-block__descr {
    margin-top: 12px;
    width: 71%;
    max-width: 464px;
  }
}
@media (max-width: 639px) {
  .mob-app .section-big-block__descr {
    width: 55%;
    hyphens: none;
  }
}
@media (max-width: 479px) {
  .mob-app .section-big-block__descr {
    width: 100%;
  }
}

.mobile-check-lists {
  background: #f6f5f8;
  font-size: 0;
}
@media (min-width: 1199px) {
  .mobile-check-lists .section-big-block__img {
    opacity: 0;
    transform: scale(0.5);
  }
}
@media (max-width: 639px) {
  .mobile-check-lists .descr-point:first-child {
    width: 70%;
  }
}
@media (max-width: 479px) {
  .mobile-check-lists .descr-point:first-child {
    width: 80%;
  }
}

.entry-modal .modal-sign-log__form {
  height: 400px;
}
@media (max-width: 639px) {
  .entry-modal .modal-sign-log__form {
    width: 95vw;
  }
}
@media (max-width: 479px) {
  .entry-modal .modal-sign-log__form {
    width: 100vw;
    height: 100vh;
  }
}

.sign-modal .modal-sign-log__form {
  height: 570px;
}
@media (max-width: 639px) {
  .sign-modal .modal-sign-log__form {
    width: 95vw;
    height: 85%;
  }
}
@media (max-width: 479px) {
  .sign-modal .modal-sign-log__form {
    height: 100vh;
    width: 100vw;
  }
}
.sign-modal .modal-sign-log__code-block {
  display: none;
}

.cookies {
  padding: 42px 0;
}
@media (max-width: 959px) {
  .cookies__title {
    font-size: 32px;
  }
}
.cookies__descr {
  margin-top: 32px;
  font-size: 18px;
}
.cookies__list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}
.cookies__point {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.cookies__point-title {
  font-size: 22px;
}
.cookies__point-descr {
  font-size: 18px;
  padding-left: 12px;
}
.cookies__point-link {
  color: #4779f6;
  font-size: 18px;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.cookies__point-link:hover {
  color: #844bfd;
}

.privacy {
  padding: 42px 0;
}
.privacy__title {
  width: 70%;
}
@media (max-width: 959px) {
  .privacy__title {
    width: 100%;
    font-size: 32px;
  }
}
.privacy__list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}
.privacy__item {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.privacy__item-title {
  font-size: 22px;
}
.privacy__item-descr {
  font-size: 18px;
}
.privacy__item-list {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}
.privacy__item-point {
  padding-left: 12px;
  font-size: 18px;
}
.privacy__item-link {
  color: #4779f6;
  font-size: 18px;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.privacy__item-link:hover {
  color: #844bfd;
}

.terms {
  padding: 42px 0;
}
@media (max-width: 959px) {
  .terms__title {
    font-size: 32px;
  }
}
.terms__descr {
  margin-top: 32px;
  font-size: 18px;
}
.terms__list {
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  row-gap: 22px;
}
.terms__point {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}
.terms__point-title {
  font-size: 22px;
}
.terms__point-descr {
  font-size: 18px;
  padding-left: 12px;
}
.terms__point-link {
  color: #4779f6;
  font-size: 18px;
  line-height: 128.57%;
  transition: all 0.2s ease-in-out;
}
.terms__point-link:hover {
  color: #844bfd;
}
.terms__date {
  margin-top: 32px;
}

.planing-works__container {
  display: flex;
  justify-content: center;
}
@media (max-width: 1199px) {
  .planing-works__container {
    width: 100%;
    justify-content: flex-start;
  }
}
.planing-works .section-block {
  flex-direction: row-reverse;
  padding-left: 100px;
  column-gap: 6%;
}
@media (max-width: 1199px) {
  .planing-works .section-block {
    padding-left: 0px;
    width: 100%;
    position: relative;
    flex-direction: row;
    width: 100%;
  }
}
@media (max-width: 959px) {
  .planing-works .section-block {
    flex-direction: column-reverse;
  }
}
.planing-works .section-block__img {
  width: 64%;
}
@media (min-width: 1199px) {
  .planing-works .section-block__img {
    transform: translateX(100%);
    opacity: 0;
  }
}
@media (max-width: 1199px) {
  .planing-works .section-block__img {
    position: absolute;
    top: 0;
    right: -10.5%;
    width: 59.5%;
    max-width: 600px;
  }
}
@media (max-width: 959px) {
  .planing-works .section-block__img {
    position: static;
    margin-top: -25px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 639px) {
  .planing-works .section-block__img {
    margin-top: 7px;
  }
}
@media (max-width: 479px) {
  .planing-works .section-block__img {
    margin-top: 16px;
  }
}
.planing-works .section-block__info {
  padding-top: 22px;
  width: 50%;
}
@media (max-width: 1199px) {
  .planing-works .section-block__info {
    width: 45%;
    padding-top: 52px;
  }
}
@media (max-width: 959px) {
  .planing-works .section-block__info {
    width: 68%;
    padding-top: 0;
  }
}
@media (max-width: 639px) {
  .planing-works .section-block__info {
    width: 92%;
  }
}
@media (max-width: 479px) {
  .planing-works .section-block__info {
    width: 100%;
  }
}
.planing-works .section-block__descr {
  width: 80%;
}
@media (max-width: 959px) {
  .planing-works .section-block__descr {
    width: 100%;
  }
}
.planing-works .section-block .section-list {
  width: 80%;
}
@media (max-width: 959px) {
  .planing-works .section-block .section-list {
    width: 100%;
  }
}

.schedule-staff__container {
  display: flex;
  justify-content: center;
}
@media (max-width: 1199px) {
  .schedule-staff__container {
    width: 100%;
    justify-content: flex-start;
  }
}
.schedule-staff .section-block {
  flex-direction: row-reverse;
  align-items: center;
  padding-left: 100px;
  column-gap: 6%;
}
@media (max-width: 1199px) {
  .schedule-staff .section-block {
    position: relative;
    width: 100%;
    padding-left: 0px;
    flex-direction: row;
  }
}
@media (max-width: 959px) {
  .schedule-staff .section-block {
    flex-direction: column-reverse;
  }
}
.schedule-staff .section-block__img {
  width: 64%;
}
@media (min-width: 1199px) {
  .schedule-staff .section-block__img {
    transform: translateX(100%);
    opacity: 0;
  }
}
@media (max-width: 1199px) {
  .schedule-staff .section-block__img {
    position: absolute;
    top: 0;
    right: -10.5%;
    width: 59.5%;
    max-width: 600px;
  }
}
@media (max-width: 959px) {
  .schedule-staff .section-block__img {
    position: static;
    margin-top: -25px;
    width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 639px) {
  .schedule-staff .section-block__img {
    margin-top: 6px;
  }
}
@media (max-width: 479px) {
  .schedule-staff .section-block__img {
    margin-top: 16px;
  }
}
.schedule-staff .section-block__info {
  margin-top: -10px;
  width: 50%;
}
@media (max-width: 1199px) {
  .schedule-staff .section-block__info {
    padding-top: 123px;
    width: 59%;
  }
}
@media (max-width: 959px) {
  .schedule-staff .section-block__info {
    padding-top: 0;
    width: 100%;
  }
}
.schedule-staff .section-block__descr {
  width: 80%;
}
@media (max-width: 479px) {
  .schedule-staff .section-block__descr {
    width: 90%;
  }
}
.schedule-staff .section-block .section-list {
  margin-top: 14px;
  width: 80%;
}
@media (max-width: 959px) {
  .schedule-staff .section-block .section-list {
    margin-top: 6px;
  }
}
@media (max-width: 479px) {
  .schedule-staff .section-block .section-list {
    width: 100%;
  }
}
.schedule-staff .section-block .try-free-btn {
  margin-top: 22px;
}
@media (max-width: 959px) {
  .schedule-staff .section-block .try-free-btn {
    margin-top: 12px;
  }
}

.services__container {
  display: flex;
  justify-content: center;
}
@media (max-width: 959px) {
  .services__container {
    flex-direction: column;
    row-gap: 161px;
    align-items: center;
  }
}
@media (max-width: 639px) {
  .services__container {
    row-gap: 34px;
  }
}
@media (max-width: 479px) {
  .services__container {
    row-gap: 18px;
  }
}
.services .section-block {
  width: 86%;
  column-gap: 12%;
}
@media (max-width: 1199px) {
  .services .section-block {
    width: 100%;
    column-gap: 2%;
  }
}
@media (max-width: 959px) {
  .services .section-block {
    flex-direction: row-reverse;
  }
}
@media (max-width: 639px) {
  .services .section-block {
    flex-direction: column-reverse;
    align-items: center;
  }
}
@media (max-width: 1199px) {
  .services .section-block__descr {
    margin-top: 2px;
  }
}
@media (max-width: 479px) {
  .services .section-block__descr {
    margin-top: 0;
  }
}
.services .section-block__img {
  margin-top: 17px;
  margin-bottom: 12px;
  width: 44%;
}
@media (min-width: 1199px) {
  .services .section-block__img {
    transform: translateY(100%);
    opacity: 0;
  }
}
@media (max-width: 1199px) {
  .services .section-block__img {
    margin-top: 0;
    margin-bottom: -7px;
    max-width: 360px;
  }
}
@media (max-width: 959px) {
  .services .section-block__img {
    margin-top: 23px;
    width: 58%;
  }
}
@media (max-width: 639px) {
  .services .section-block__img {
    width: 48%;
    margin-top: 29px;
  }
}
@media (max-width: 479px) {
  .services .section-block__img {
    margin-top: 18px;
    width: 73%;
  }
}
.services .section-block__info {
  width: 85%;
}
@media (max-width: 1199px) {
  .services .section-block__info {
    width: 90%;
  }
}
@media (max-width: 959px) {
  .services .section-block__info {
    width: inherit;
  }
}
@media (max-width: 639px) {
  .services .section-block .try-free-btn {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .services .section-block .try-free-btn {
    justify-content: flex-start;
  }
}
.services .qr-info-mobile {
  display: none;
}
@media (max-width: 959px) {
  .services .qr-info-mobile {
    display: flex;
    width: 90%;
  }
}
@media (max-width: 639px) {
  .services .qr-info-mobile {
    width: 100vw;
    align-items: center;
    border-radius: 0px;
    padding: 29px;
    padding-bottom: 20px;
  }
}
@media (max-width: 479px) {
  .services .qr-info-mobile {
    padding: 24px;
    padding-bottom: 16px;
  }
}
@media (max-width: 959px) {
  .services .qr-info-mobile .qr-block__descr {
    margin-top: 2px;
  }
}
@media (max-width: 639px) {
  .services .qr-info-mobile .qr-block__descr {
    text-align: center;
    margin-top: 2px;
    width: 64%;
  }
}
@media (max-width: 479px) {
  .services .qr-info-mobile .qr-block__descr {
    width: 100%;
  }
}
@media (max-width: 639px) {
  .services .qr-info-mobile .qr-block__img {
    width: 160px;
    margin-top: 14px;
    border-radius: 8px;
  }
}
@media (max-width: 479px) {
  .services .qr-info-mobile .qr-block__img {
    margin-top: 10px;
  }
}

.work-now {
  padding: 38px 0;
  background: linear-gradient(268deg, #f4ca36 0%, #fede7d 100%);
}
@media (max-width: 1199px) {
  .work-now {
    padding: 16px 0;
  }
}
@media (max-width: 959px) {
  .work-now {
    padding: 22px 0;
  }
}
@media (max-width: 639px) {
  .work-now {
    padding: 20px 0;
  }
}
.work-now__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 24px;
}
@media (max-width: 959px) {
  .work-now__container {
    row-gap: 16px;
  }
}
.work-now__title {
  color: #000;
  font-size: 36px;
  font-weight: 700;
  line-height: 133.33%;
  user-select: none;
}
@media (max-width: 1199px) {
  .work-now__title {
    text-align: center;
    width: 50%;
  }
}
@media (max-width: 959px) {
  .work-now__title {
    width: 70%;
  }
}
@media (max-width: 639px) {
  .work-now__title {
    width: 80%;
    line-height: 116.66%;
  }
}
@media (max-width: 479px) {
  .work-now__title {
    font-size: 20px;
    line-height: 23.2px;
  }
}
.work-now__descr {
  text-align: center;
  width: 33%;
  font-size: 16px;
  line-height: 137.5%;
}
@media (max-width: 1199px) {
  .work-now__descr {
    width: 40%;
  }
}
@media (max-width: 959px) {
  .work-now__descr {
    width: 60%;
  }
}
@media (max-width: 639px) {
  .work-now__descr {
    width: 80%;
  }
}
@media (max-width: 479px) {
  .work-now__descr {
    width: 100%;
  }
}
.work-now__img-mob {
  display: none;
}
@media (max-width: 959px) {
  .work-now__img-mob {
    display: block;
    margin-top: -16px;
    width: 74%;
  }
}
@media (max-width: 639px) {
  .work-now__img-mob {
    margin-top: -8px;
  }
}
@media (max-width: 479px) {
  .work-now__img-mob {
    margin-top: -11px;
    width: 113%;
  }
}
.work-now__btns {
  display: flex;
  align-items: center;
  column-gap: 16px;
}
@media (max-width: 959px) {
  .work-now__btns {
    margin-top: -22px;
    column-gap: 8px;
  }
}
@media (max-width: 639px) {
  .work-now__btns {
    margin-top: -13px;
  }
}
@media (max-width: 479px) {
  .work-now__btns {
    flex-wrap: wrap;
    margin-top: -16px;
    row-gap: 8px;
  }
}
.work-now__btn-purple {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 12px 16px 12px 24px;
  display: flex;
  align-items: center;
  column-gap: 12px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  border-radius: 60px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all 0.2s ease-in-out;
}
.work-now__btn-purple svg {
  width: 24px;
  height: 24px;
  stroke: #fff;
  fill: #fff;
  transition: all 0.2s ease-in-out;
}
.work-now__btn-purple:hover {
  transform: scale(1.05);
}
.work-now__btn-purple:hover svg {
  transform: translateX(8px);
}
@media (max-width: 959px) {
  .work-now__btn-purple {
    width: 226px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .work-now__btn-purple {
    width: 100%;
  }
}
.work-now__btn {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  padding: 15px 24px;
  color: #000;
  font-size: 16px;
  line-height: 112.5%;
  border-radius: 60px;
  background: #fff;
  transition: all 0.2s ease-in-out;
}
@media (max-width: 959px) {
  .work-now__btn {
    width: 226px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (max-width: 479px) {
  .work-now__btn {
    width: 100%;
  }
}
.work-now__btn:hover {
  background: #844bfd;
  color: #fff;
}
.work-now__img {
  position: absolute;
}
@media (max-width: 959px) {
  .work-now__img {
    display: none;
  }
}
.work-now__img_left {
  top: 13%;
  left: -20px;
  width: 29%;
}
@media (max-width: 1199px) {
  .work-now__img_left {
    top: 6%;
    left: -50px;
    width: 36%;
  }
}
.work-now__img_right {
  top: 13%;
  right: -20px;
  width: 29%;
}
@media (max-width: 1199px) {
  .work-now__img_right {
    top: 6%;
    right: -38px;
    width: 36%;
  }
}

/*!*
 * ВНИМАНИЕ! Этот файл генерируется автоматически.
 * Любые изменения этого файла будут потеряны при следующей компиляции.
 * Любое изменение проекта без возможности компиляции ДОЛЬШЕ И ДОРОЖЕ в 2-5 раз.
 */