.section-list {
  @include listReset();
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  &__item {
    display: flex;
    align-items: center;
    column-gap: 10px;
    svg {
      flex-shrink: 0;
      width: 16px;
      height: 16px;
      stroke: $light-blue;
      fill: transparent;
    }
    span {
      color: $black;
      font-size: 14px;
      line-height: 157.143%;
    }
  }
}
