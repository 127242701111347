.loader{
  position: relative;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: conic-gradient($white, transparent);
  animation: rotation 1s linear infinite;
  transition: all .2s ease-in-out;
  &:before{
    content: '';
    position: absolute;
    width: 10px;
    height: 10px;
    background: #9C42F5;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: all .2s ease-in-out;
  }
  &::after{
    content: '';
    position: absolute;
    width: 4px;
    height: 4px;
    background: $white;
    border-radius: 50%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    transition: all .2s ease-in-out;
  }
  @keyframes rotation {
    from{
      transform: rotate(360deg);
    }
    to {
      transform: rotate(0);
    }

  }
}
