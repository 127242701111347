.brends {
  &__container {
    padding: 0 110px;
    position: relative;
    @media (max-width: $desktop) {
      padding: 0px 86px;
    }
    @media (max-width: $tablet) {
      padding: 0 10px;
    }
    @media (max-width: $mobile) {
      width: 100%;
      overflow: hidden;
      padding: 0 8px;
    }
  }
}
.brends-swiper {
  width: 100%;
  overflow: hidden;
  @media (max-width: $mobile) {
    height: 230px;
  }
  &__wrapper {
    height: 100px;
    display: flex;
    align-items: center;
    @media (max-width: $mobile) {
      height: 100%;
    }
  }
  &__slide {
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: $mobile) {
      margin-top: 0 !important;
      height: calc((100% - 30px) / 2);
    }
    img {
      filter: grayscale(1);
      transition: all 0.2s ease-in-out;
      &:hover {
        filter: grayscale(0);
      }
      @media (max-width: $mobile) {
        max-height: 100%;
      }
    }
  }
}

.swiper-brend-next,
.swiper-brend-prev {
  @include btnReset();
  z-index: 2;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: $light-grey;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  @media (max-width: $tablet) {
    display: none;
  }
  &:hover {
    background: $hoverColor;
    svg {
      stroke: $white;
    }
  }
  svg {
    width: 24px;
    height: 24px;
    fill: none;
    stroke: $text-color;
    transition: all 0.2s ease-in-out;
  }
}
.swiper-brend-next {
  right: 14px;
}
.swiper-brend-prev {
  left: 14px;
}
.swiper-slide-2 {
  img {
    width: 65%;
  }
}
.swiper-slide-3 {
  img {
    width: 60%;
  }
}
.swiper-slide-5 {
  img {
    width: 75%;
  }
}
.swiper-slide-12 {
  img {
    width: 80%;
  }
}
.swiper-slide-13 {
  img {
    width: 40%;
  }
}
