.textarea-block {
  position: relative;
  height: 72px;
  width: 100%;
  &__label {
    position: absolute;
    top: 17%;
    left: 16px;
    color: $grey-secondary;
    font-size: 12px;
    font-weight: 600;
    line-height: 133.33%;
    transition: all 0.2s ease;
  }
  &__textarea {
    width: 100%;
    height: 100%;
    padding: 16px 16px 0px 16px;
    color: $text-color;
    font-family: $default-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 114.28%;
    border-radius: 12px;
    border: 1px solid $light-grey-secondary;
    transition: all 0.2s ease-in-out;
    resize: none;
    &.error {
      border-color: $color-error;
    }
    &:focus {
      outline: none;
      border-color: $text-color;
    }
    &:hover {
      background: $light-grey;
    }
  }
  &__error {
    padding-left: 18px;
    color: $color-error;
    font-size: 12px;
    line-height: 133.33%;
    background-image: url('/img/icons/error.svg');
    background-repeat: no-repeat;
    visibility: hidden;
    opacity: 0;
    transition: all .2s ease-in-out;
    background-position-y: -2px;
    &.is-active{
      visibility: visible;
      opacity: 1;
    }
  }
}
