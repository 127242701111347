.privacy {
  padding: 42px 0;
  &__title {
    width: 70%;
    @media (max-width: $tablet) {
      width: 100%;
      font-size: 32px;
    }
  }
  &__list {
    @include listReset;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    row-gap: 22px;
  }
  &__item {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
  }
  &__item-title {
    font-size: 22px;
  }
  &__item-descr {
    font-size: 18px;
  }
  &__item-list {
    @include listReset;
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  &__item-point {
    padding-left: 12px;
    font-size: 18px;
  }
  &__item-link {
    color: $blue;
    font-size: 18px;
    line-height: 128.57%;
    transition: all 0.2s ease-in-out;
    &:hover {
      color: $hoverColor;
    }
  }
}
