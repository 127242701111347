.section-title {
  margin: 0;
  padding: 0;
  color: $text-color;
  font-size: 48px;
  font-weight: 700;
  line-height: 108.33%;
  user-select: none;
  @media (max-width: $tablet) {
    font-size: 36px;
    line-height: 116.66%;
  }
  @media (max-width: $mobile) {
    font-size: 20px;
    line-height: 23.2px;
  }
}
