@mixin fontRuble($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
		font-family: $f-name;
		font-style:  $f-style;
		font-weight: $f-weight;
		src: url('../fonts/#{$f-url}.ttf') format('truetype');
	}
}
@mixin font($f-name, $f-style, $f-weight, $f-url) {
	@font-face {
	  font-family: $f-name;
	  font-style:  $f-style;
	  font-weight: $f-weight;
	  src: url('../fonts/#{$f-url}.woff2') format('woff2'),
	  url('../fonts/#{$f-url}.woff') format('woff'),
	  url('../fonts/#{$f-url}.ttf') format('truetype'),
	  url('../fonts/#{$f-url}.eot'),
	  url('../fonts/#{$f-url}.eot?#iefix') format('embedded-opentype');
	}
}

// @include font('Open Sans', normal, 400, 'open-sans/open-sans-v17-latin_cyrillic-regular');
@include font('Open Sans', normal, 400, 'open-sans/OpenSans-Regular');
@include font('Open Sans', normal, 600, 'open-sans/OpenSans-SemiBold');
@include font('Open Sans', normal, 700, 'open-sans/OpenSans-Bold');
@include font('Roboto', normal, 400, 'roboto/Roboto-Regular');
@include font('Roboto', normal, 500, 'roboto/Roboto-Medium');
@include font('Roboto', normal, 700, 'roboto/Roboto-Bold');
@include font('Roboto', normal, 900, 'roboto/Roboto-Black');
