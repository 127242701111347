.header {
  background: $light-grey;
  &__container {
    padding: 24px 4px;
    display: flex;
    align-items: center;
    column-gap: $standart-gap;
    @media (max-width: $desktop) {
      padding: 24px 14px;
      column-gap: 32px;
    }
    @media (max-width: $tablet) {
      justify-content: space-between;
    }
    @media (max-width: $mobile-big) {
      padding: 24px 8px;
    }
    @media (max-width: $mobile) {
      flex-direction: row-reverse;
      padding: 24px 13px 24px 15px;
    }
  }
  .burger {
    display: none;
    @media (max-width: $tablet) {
      display: block;
      margin-right: 12%;
    }
    @media (max-width: $mobile-big) {
      margin-right: 17%;
    }
    @media (max-width: $mobile) {
      margin-right: 0;
    }
  }
  &__logo {
    max-width: 122px;
    @media (max-width: $mobile) {
      max-width: 104px;
    }
  }
  .sign-in-btn {
    @media (max-width: $tablet) {
      display: none;
    }
  }
  .log-in-btn {
    @media (max-width: $mobile) {
      display: none;
    }
  }
  .lang-select {
    @media (max-width: $tablet) {
      display: none;
    }
  }
}
.header-nav {
  width: 100%;
  @media (max-width: $tablet) {
    display: none;
  }
  &__list {
    @include listReset();
    display: flex;
    column-gap: $standart-gap;
    display: none;
    @media (max-width: $desktop) {
      column-gap: 27px;
    }
  }

  &__link {
    color: $text-color;
    font-size: 16px;
    font-weight: 600;
    line-height: 112.5%;
    transition: all 0.2s ease-in-out;
    user-select: none;
    hyphens: none;
    &:hover {
      color: $hoverColor;
    }
  }
}

.limboo-logo{
  max-width: 180px;
  @media (max-width: $mobile) {
    max-width: 130px;
  }
}
