// Widths   420 479 575 640 767 991 1199 1400
$mobile-sm: 320px;
$mobile: 479px;
$mobile-big: 639px;
$tablet: 959px;
// $desktop: 1099px;
$desktop: 1199px;

$grid-spacing: 20px;
$grid-spacing-sm: 10px;

$standart-gap: 32px;
$section-gap: 58px;

$base-img: '/img/';

$font-size: 16px;
$line-height: 1.5;
// Fonts
$default-font: 'Open Sans', sans-serif; //400/600/700
$additional-font: 'Roboto', sans-serif; //400/500/700/900

// Colors
$text-color: #101d50;
$color-error: #fa5959;
$white: #fff;
$black: #000;
$green: #28ba48;
$light-grey: #f6f5f8;
$light-grey-secondary: #e8ecee;
$grey-secondary: #6f7782;
$grey-primary: #eee;
$light-blue: #3390ff;
$light-blue-secondary: #4084f8;
$blue: #4779f6;
$dark-brown: #363a4a;
$ligh-brown: #5b6177;
$shadow: rgba(0, 0, 0, 0.16);
$hoverColor: #844bfd;
$hoverDigitaskColor: #fe5840;
