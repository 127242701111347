.sign-in-btn {
  @include btnReset();
  white-space: nowrap;
  color: $text-color;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  transition: all .2s ease-in-out;
  user-select: none;
  &:hover{
    color: $hoverColor;
  }
}
