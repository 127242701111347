.work-now {
  padding: 38px 0;
  background: linear-gradient(268deg, #f4ca36 0%, #fede7d 100%);
  @media (max-width: $desktop) {
    padding: 16px 0;
  }
  @media (max-width: $tablet) {
    padding: 22px 0;
  }
  @media (max-width: $mobile-big) {
    padding: 20px 0;
  }
  &__container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 24px;
    @media (max-width: $tablet) {
      row-gap: 16px;
    }
  }

  &__title {
    color: $black;
    font-size: 36px;
    font-weight: 700;
    line-height: 133.33%;
    user-select: none;
    @media (max-width: $desktop) {
      text-align: center;
      width: 50%;
    }
    @media (max-width: $tablet) {
      width: 70%;
    }
    @media (max-width: $mobile-big) {
      width: 80%;
      line-height: 116.66%;
    }
    @media (max-width: $mobile) {
      font-size: 20px;
      line-height: 23.2px;
    }
  }
  &__descr {
    text-align: center;
    width: 33%;
    font-size: 16px;
    line-height: 137.5%;
    @media (max-width: $desktop) {
      width: 40%;
    }
    @media (max-width: $tablet) {
      width: 60%;
    }
    @media (max-width: $mobile-big) {
      width: 80%;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
  &__img-mob {
    display: none;
    @media (max-width: $tablet) {
      display: block;
      margin-top: -16px;
      width: 74%;
    }
    @media (max-width: $mobile-big) {
      margin-top: -8px;
    }
    @media (max-width: $mobile) {
      margin-top: -11px;
      width: 113%;
    }
  }
  &__btns {
    display: flex;
    align-items: center;
    column-gap: 16px;
    @media (max-width: $tablet) {
      margin-top: -22px;
      column-gap: 8px;
    }
    @media (max-width: $mobile-big) {
      margin-top: -13px;
    }
    @media (max-width: $mobile) {
     flex-wrap: wrap;
     margin-top: -16px;
     row-gap: 8px;
    }
  }
  &__btn-purple {
    @include btnReset();
    @include purpleBtnWhitArrow();
    @media (max-width: $tablet) {
      width: 226px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $mobile) {
     width: 100%;
    }
  }
  &__btn {
    @include btnReset();
    padding: 15px 24px;
    color: $black;
    font-size: 16px;
    line-height: 112.5%;
    border-radius: 60px;
    background: $white;
    transition: all 0.2s ease-in-out;
    @media (max-width: $tablet) {
      width: 226px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (max-width: $mobile) {
     width: 100%;
    }
    &:hover {
      background: $hoverColor;
      color: $white;
    }
  }
  &__img {
    position: absolute;
    @media (max-width: $tablet) {
      display: none;
    }

    &_left {
      top: 13%;
      left: -20px;
      width: 29%;
      @media (max-width: $desktop) {
        top: 6%;
        left: -50px;
        width: 36%;
      }
    }
    &_right {
      top: 13%;
      right: -20px;
      width: 29%;
      @media (max-width: $desktop) {
        top: 6%;
        right: -38px;
        width: 36%;
      }
    }
  }
}
