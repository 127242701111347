.hero {
  background: $light-grey;
  @media (max-width: $tablet) {
    padding-top: 28px;
  }
  @media (max-width: $mobile-big) {
    padding-top: 25px;
  }
  @media (max-width: $mobile) {
    padding-top: 30px;
  }
  &__container {
    position: relative;
    width: 100%;
    display: flex;
    @media (max-width: $tablet) {
      display: flex;
      flex-direction: column;
    }
    @media (max-width: $mobile) {
      align-items: center;
    }
  }
  &__left {
    position: relative;
    z-index: 3;
    padding: 46px 100px 64px;
    width: 64%;
    @media (max-width: $desktop) {
      padding: 46px 0px 64px;
      width: 54%;
    }
    @media (max-width: $tablet) {
      width: 100%;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
  }
  &__right {
    z-index: 2;
    position: absolute;
    bottom: 0;
    right: -2%;
    height: 100%;
    width: 60%;
    @media (max-width: $tablet) {
      overflow: hidden;
      position: relative;
      right: 0;
      width: 100%;
      margin-top: 50px;
    }
    @media (max-width: $mobile-big) {
      width: 100vw;
      right: 8px;
    }
    @media (max-width: $mobile) {
      max-height: 230px;
      margin-top: 20px;
      margin-left: 16px;
      width: 80%;
    }
  }
  &__title {
    color: $text-color;
    font-size: 48px;
    font-weight: 700;
    line-height: 108.33%;
    @media (max-width: $tablet) {
      font-size: 36px;
      width: 56%;
    }
    @media (max-width: $mobile-big) {
      font-size: 24px;
      line-height: 133.33%;
      width: 53%;
    }
    @media (max-width: $mobile) {
      width: 80%;
    }
  }
  &__descr {
    margin-top: 14px;
    width: 75%;
    hyphens: none;
    color: $text-color;
    font-size: 16px;
    font-weight: 400;
    line-height: 137.5%;
    @media (max-width: $tablet) {
      width: 60%;
      margin-top: 20px;
    }
    @media (max-width: $mobile-big) {
      width: 80%;
      margin-top: 17px;
    }
    @media (max-width: $mobile) {
      margin-top: 34px;
    }
  }
  &__tabs.hero__tabs {
    margin-top: $standart-gap;
    display: flex;
    flex-wrap: wrap;
    column-gap: 8px;
    row-gap: 8px;
    @media (max-width: $tablet) {
      justify-content: center;
      margin-top: 18px;
      width: 81%;
    }
    @media (max-width: $mobile-big) {
      width: 100%;
    }
    @media (max-width: $mobile) {
      display: none;
    }
  }
  &__btn {
    @include btnReset();
    @include purpleBtnWhitArrow();
    margin-top: 60px;
    box-shadow: 0px 0px 41px 0px rgba(147, 73, 246, 0.45);
    @media (max-width: $tablet) {
      margin-top: 20px;
    }
  }

  .swiper-pagination-bullet.swiper-pagination-bullet {
    margin: 0;
    width: fit-content;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 6px 14px 6px 29px;
    color: $black;
    font-size: 14px;
    font-weight: 400;
    line-height: 128.57%;
    border-radius: 10px;
    background: $white;
    background-image: url('/img/icons/tip.svg');
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px;
    box-shadow: 0px 4px 16px 0px $shadow;
    border: 1px solid transparent;
    user-select: none;
    opacity: 1;
    transition: all 0.3s ease-in-out;
    @media (max-width: $mobile-big) {
      height: 26px;
      padding: 6px 8px 6px 24px;
      background-size: 12px 12px;
      background-position-x: 6px;
      border-radius: 8px;
    }
    &:hover {
      border-color: $hoverColor;
    }
    &:active {
      transform: scale(0.95);
    }
    &-active {
      border-color: $light-blue;
    }
  }
  .swiper-wrapper {
    @media (max-width: $tablet) {
      display: flex;
      align-items: flex-end;
    }
    @media (max-width: $mobile) {
      max-height: inherit;
    }
  }
}

.hero-tab {
  font-size: 0;
  @media (max-width: $tablet) {
    max-height: 445px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  @media (max-width: $mobile) {
    // max-height: 230px;
    max-height: 229px;
  }

  picture {
    position: absolute;
    bottom: 0;
    max-height: 100%;
    @media (max-width: $tablet) {
      position: static;
      max-height: inherit;
      object-fit: cover;
      object-position: top;
    }
  }
}

.hero-tab-1 {
  overflow-y: hidden;
  &__img {
    // width: 67%;
    width: 474px;
    left: 26%;
    @media (max-width: $desktop) {
      left: 27%;
    }
    @media (max-width: $tablet) {
    }

  }
}
.hero-tab-2 {
  &__img {
    width: 694px;
    left: 18%;

  }
}
.hero-tab-3 {
  overflow-y: hidden;
  &__img {
    width: 480px;
    left: 26%;
  }
}
.hero-tab-4 {
  &__img {
    width: 720px;
    left: 16%;
  }
}
.hero-tab-5 {
  &__img {
    width: 644px;
    left: 24%;
  }
}
.hero-tab-6 {
  &__img {
    width: 660px;
    left: 4%;
  }
}
