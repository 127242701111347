.log-in-btn {
  @include btnReset();
  white-space: nowrap;
  padding: 12px 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 112.5%;
  color: $white;
  border-radius: 12px;
  background: linear-gradient(90deg, #4579f5 0.05%, #9c42f5 99.96%);
  transition: all .2s ease-in-out;
  user-select: none;
  @media (max-width: $tablet) {
    padding: 12px 32px;
  }
  &:hover{
    transform: scale(1.03);
  }
}
