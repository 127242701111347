.pluses-item {
  @include btnReset();
  position: relative;
  padding: 8px;
  display: flex;
  align-items: center;
  column-gap: 14px;
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  @media (max-width: $tablet) {
    flex-direction: column;
    row-gap: 6px;
  }
  &:hover {
    background: $light-grey;
    .pluses-item__svg {
      opacity: 1;
      visibility: visible;
    }
  }
  &__img {
    width: 60px;
    height: 60px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    @media (max-width: $mobile-big) {
      width: 36px;
      height: 36px;
      border-radius: 12px;
    }
    svg {
      fill: none;
      stroke: $white;
    }
  }
  &__title {
    width: 62%;
    text-align: left;
    color: $black;
    font-size: 16px;
    font-weight: 400;
    line-height: 112.5%;
    hyphens: none;
    user-select: none;
    @media (max-width: $tablet) {
      text-align: center;
      width: 100%;
    }
    @media (max-width: $mobile-big) {
      font-size: 14px;
      line-height: 128.57%;
    }
  }
  &__svg {
    position: absolute;
    width: 24px;
    height: 24px;
    top: 8px;
    right: 8px;
    fill: $green;
    stroke: $green;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in-out;
  }
}
