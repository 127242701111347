.mob-app {
  min-height: 639px;
  position: relative;
  background: $light-grey;
  @media (max-width: $desktop) {
    min-height: 560px;
  }
  @media (max-width: $tablet) {
    min-height: 464px;
  }
  @media (max-width: $mobile-big) {
    min-height: 583px;
  }
  @media (max-width: $mobile) {
    min-height: 677px;
  }

  &__container {
    padding-top: 120px;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: $desktop) {
      padding-top: 80px;
    }
    @media (max-width: $tablet) {
      padding-top: 35px;
    }
    @media (max-width: $mobile-big) {
      align-items: flex-start;
    }
    @media (max-width: $mobile) {
      padding-top: 32px;
    }
  }
  .section-title {
    text-align: center;
    width: 60%;
    @media (max-width: $mobile-big) {
      text-align: left;
      width: 80%;
    }
  }
  .section-block__img {
    position: absolute;
    top: 114px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 0;
    width: 1290px;
    display: flex;
    justify-content: center;

    @media (min-width: $desktop) {
      transform: translate(-50%, 100%);
      opacity: 0;
    }
    @media (max-width: $desktop) {
      top: 96px;
      width: 1140px;
    }
    @media (max-width: $tablet) {
      top: 130px;
      width: 820px;
    }
    @media (max-width: $mobile-big) {
      width: 565px;
      top: 108px;
      left: inherit;
      right: -42px;
      transform: translateX(0);
    }
    @media (max-width: $mobile) {
      margin: 0 auto;
      width: 400px;
      // width: 119vw;
      top: 257px;
      right: inherit;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .section-big-block__descr {
    margin-top: 24px;
    font-size: 16px;
    font-style: normal;
    line-height: 137.5%;

    @media (max-width: $desktop) {
      width: 36%;
    }
    @media (max-width: $tablet) {
      margin-top: 12px;
      width: 71%;
      max-width: 464px;
    }
    @media (max-width: $mobile-big) {
      width: 55%;
      hyphens: none;
    }
    @media (max-width: $mobile) {
      width: 100%;
    }
  }
}
